import React from "react";
import { RiArrowLeftLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
{/* interface Props { */}
{/*   title: string; */}
{/*   subTitle: string; */}
{/*   action?: React.ReactNode; */}
{/*   children: React.ReactNode; */}
{/* } */}
const PageViewContainer = ({ title, subTitle, action, children }) => {
  let history = useHistory();
  return (
    <div className="grid grid-rows-page-row grid-cols-page-col flex-1 bg-white">
      <div className="col-start-1 row-start-1 row-end-2 flex items-center justify-between px-8 border-b">
        <div className="flex flex-none items-center">
          <button onClick={()=>history.goBack()} className="flex-grow-0 p-3 mr-3 border border-gray-300 text-primary-500 hover:border-primary-400 transition-all rounded-lg">
            <RiArrowLeftLine />
          </button>
          <div>
            <small className="text-primary-500 font-semibold">{subTitle}</small>
            <h1 className="font-extrabold text-primary-600 leading-6">
              {title}
            </h1>
          </div>
        </div>
        {action && <div>{action}</div>}
      </div>
      <div className="col-start-1 row-start-2 row-end-3">{children}</div>
    </div>
  );
};
export default PageViewContainer;
