import React from "react";
import { RiMenuFill} from "react-icons/ri";


{/* interface Props { */}
{/*   sidebarTrigger: Function; */}
{/* } */}

const HeaderIcon = ({ sidebarTrigger }) => {

  return (
    <>
      <div className=" col-start-1 flex items-end justify-end z-10">
        <span className="text-primary-600 desktop:block mobile:hidden">
          <svg
            className="fill-current"
            width="40"
            viewBox="0 0 34 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M24.6994 1.71945V8.15148C23.5206 8.15136 22.3534 8.38333 21.2644 8.83415C20.1753 9.28496 19.1858 9.9458 18.3523 10.7789C17.5187 11.612 16.8575 12.6011 16.4064 13.6897C15.9553 14.7782 15.7231 15.945 15.7231 17.1232H2.47545C2.26027 17.1232 2.04721 17.0808 1.84847 16.9984C1.64972 16.9159 1.46919 16.7951 1.31722 16.6429C1.16524 16.4906 1.04481 16.3099 0.962812 16.111C0.880814 15.9122 0.838862 15.6991 0.83936 15.484V1.71945C0.83936 1.2857 1.01173 0.869725 1.31856 0.56302C1.62538 0.256316 2.04153 0.0840111 2.47545 0.0840111H23.0633C23.4971 0.0842617 23.9131 0.256647 24.2199 0.563297C24.5266 0.869947 24.6991 1.28578 24.6994 1.71945Z" />
            <path d="M33.6747 17.1194C33.6504 19.4831 32.694 21.7416 31.0134 23.4044C29.3328 25.0673 27.0636 26 24.6989 26C22.3342 26 20.0651 25.0673 18.3844 23.4044C16.7038 21.7416 15.7474 19.4831 15.7231 17.1194H23.0633C23.4971 17.1192 23.9131 16.9468 24.2199 16.6402C24.5267 16.3335 24.6991 15.9177 24.6994 15.484V8.1477C25.878 8.1477 27.0451 8.37976 28.1341 8.83063C29.223 9.2815 30.2124 9.94236 31.0459 10.7755C31.8793 11.6086 32.5404 12.5976 32.9915 13.6861C33.4425 14.7746 33.6747 15.9413 33.6747 17.1194Z" />
          </svg>
        </span>
        <button
          className="text-primary-500 desktop:hidden mobile:mobile text-3xl focus:outline-none focus:shadow-none"
          onClick={() => sidebarTrigger()}
        >
          <RiMenuFill />
        </button>
      </div>
    </>
  );
};

export default HeaderIcon;
