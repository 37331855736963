import React, { useState, useCallback } from "react";
import CustomiserUnit from "../../../components/CustomiserUnit";
import ColorPicker from "../../../components/ColorPicker";
import GradientPicker from "../../../components/GradientPicker";
import Select from "@atlaskit/select";
import { RadioGroup } from "@atlaskit/radio";
import {
  RiCheckboxBlankCircleFill,
  RiCheckboxBlankCircleLine,
} from "react-icons/ri";
import { useWorkspace } from "../../../WorkspaceContext";

const LookAndFeel = (props) => {
  const workspace = useWorkspace();

  const widgetStatus = [
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-green-400 mr-2 text-lg" />{" "}
          Active{" "}
        </div>
      ),
      value: true,
    },
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleLine className="flex-none text-red-600 mr-2 text-lg" />{" "}
          Deactivated{" "}
        </div>
      ),
      value: false,
    },
  ];

  const alignment = [
    { label: "Right", value: "right" },
    { label: "Left", value: "left" },
  ];

  return (
    <>
      <CustomiserUnit
        title="Enable Corner Widget"
        description="Use this option to temporarity hide/unhide the Corner Widget on your website "
      >
        <Select
          value={widgetStatus.find(
            (option) => option.value === props.values.isActive
          )}
          onChange={(e) => props.setFieldValue("isActive", e.value)}
          className="w-48 mr-2 "
          classNamePrefix="react-select"
          options={widgetStatus}
        />
      </CustomiserUnit>

      <CustomiserUnit
        title="Widget Color"
        description="Select the color to match your brand's identity"
      >
        <GradientPicker
          startColor={props.values.bgStartColor}
          endColor={props.values.bgEndColor}
          onStartColorChange={(startColor) => {
            props.setFieldValue("bgStartColor", startColor);
          }}
          onEndColorChange={(endColor) => {
            props.setFieldValue("bgEndColor", endColor);
          }}
        />
      </CustomiserUnit>

      <CustomiserUnit
        title="Widget Alignment"
        description="Define on which side of the website the widget should be rendered"
      >
        <Select
          value={alignment.find(
            (option) => option.value === props.values.alignment
          )}
          onChange={(e) => props.setFieldValue("alignment", e.value)}
          className="w-48 mr-2 "
          classNamePrefix="react-select"
          options={alignment}
          placeholder="Select widget position"
        />
      </CustomiserUnit>
    </>
  );
};

export default LookAndFeel;
