import React from "react";
import Button from "../Button";
import Dropdown from "../Dropdown";
import { RiDatabaseLine, RiSettings4Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";

const CategoryHeader = () => {
  return (
    <div className=" col-start-2 col-end-2 p-3 flex justify-end z-10 items-center">
      <div className="mx-4 flex">
        <Button
          onClick={() => {
            window.location.href =
              "https://trello.com/b/pg3WraGU/support-corner-product-roadmap";
          }}
          type="link"
          className="text-gray-500 flex items-center"
          icon={<div className="transform rotate-180">
          <RiDatabaseLine />
        </div>}
        >
            <small className="text-sm ">Product Roadmap</small>
        </Button>
        <NavLink to="/settings/account/billing/" strict> 
          <Button icon={<RiSettings4Line />} type="link" className="text-gray-500 flex items-center">
              
              <small className="text-sm">Settings</small>
          </Button>
        </NavLink>
      </div>
    </div>
  );
};
export default CategoryHeader;
