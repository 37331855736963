import React, { useState } from "react";
import toaster from "toasted-notes";
import { useQuery, useMutation, useQueryCache } from "react-query";
import {
  Switch,
  Route,
  useLocation,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import { RiEyeLine } from "react-icons/ri";
import Assistant from "./Assistant";
import LookAndFeel from "./LookAndFeel";
import Preferences from "./Preferences";
import PageViewContainer from "../../../components/structure/PageViewContainer";
import Button from "../../../components/Button";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Formik } from "formik";
import CornerWidgetPreview from "../Previews/CornerWidgetPreview";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../api/widgets";
import { useGraphQl } from "../../../GraphqlClient";
import { useWorkspace } from "../../../WorkspaceContext";

const CornerWidgetPage = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const pageRoute = location.pathname.split("/")[3];
  const graphQL = useGraphQl();
  const workspace = useWorkspace();
  const queryCache = useQueryCache();
  const { data, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
    refetch();
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryWidgetSettings");
      toaster.notify("Widget settings saved successfully", {
        duration: 2000,
      });
    },
  });

  const getTitle = () => {
    switch (pageRoute) {
      case "look-and-feel":
        return "Look & Feel";
      case "preferences":
        return "Preferences";
      default:
        return "Look & Feel";
    }
  };

  const initialValues = {
    id: data && data.widgets_cornerwidget[0].id,
    isActive: data && data.widgets_cornerwidget[0].common_config.isActive,
    contextConfigs: {
      assistant: {
        isActive:
          data &&
          data.widgets_cornerwidget[0].common_config.contextConfigs.assistant
            .isActive,
        search:
          data &&
          data.widgets_cornerwidget[0].common_config.contextConfigs.assistant
            .search,
        introEmoji:
          data &&
          data.widgets_cornerwidget[0].common_config.contextConfigs.assistant
            .introEmoji,
        contactOptions: {
          messenger:
            data &&
            data.widgets_cornerwidget[0].common_config.contextConfigs.assistant
              .contactOptions.messenger,
          whatsapp:
            data &&
            data.widgets_cornerwidget[0].common_config.contextConfigs.assistant
              .contactOptions.whatsapp,
          email:
            data &&
            data.widgets_cornerwidget[0].common_config.contextConfigs.assistant
              .contactOptions.email,
          sms:
            data &&
            data.widgets_cornerwidget[0].common_config.contextConfigs.assistant
              .contactOptions.sms,
        },
      },

      cart: {
        isActive:
          data &&
          data.widgets_cornerwidget[0].common_config.contextConfigs.cart
            .isActive,
      },
      offers: {
        isActive:
          data &&
          data.widgets_cornerwidget[0].common_config.contextConfigs.offers
            .isActive,
      },
    },
    bgStartColor:
      data && data.widgets_cornerwidget[0].common_config.bgStartColor,
    bgEndColor: data && data.widgets_cornerwidget[0].common_config.bgEndColor,
    blockedUrls: data && data.widgets_cornerwidget[0].common_config.blockedUrls,
    hideCocaBranding:
      data && data.widgets_cornerwidget[0].common_config.hideCocaBranding,
    autoTranslate:
      data && data.widgets_cornerwidget[0].common_config.autoTranslate,
    zIndex: data && data.widgets_cornerwidget[0].common_config.zIndex,
    logoUrl: data && data.widgets_cornerwidget[0].common_config.logoUrl,
    alignment: data && data.widgets_cornerwidget[0].common_config.alignment,
    trackingPixels: {
      gaPixel:
        data &&
        data.widgets_cornerwidget[0].common_config.trackingPixels.gaPixel,
      fbPixel:
        data &&
        data.widgets_cornerwidget[0].common_config.trackingPixels.fbPixel,
    },
  };
  if (data)
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          const commonConfig = {
            common_config: {
              id: values.id,
              isActive: values.isActive,
              contextConfigs: {
                assistant: {
                  isActive: values.contextConfigs.assistant.isActive,
                  introEmoji: values.contextConfigs.assistant.introEmoji,
                  search: values.contextConfigs.assistant.search,
                  contactOptions: {
                    whatsapp:
                      values.contextConfigs.assistant.contactOptions.whatsapp,
                    messenger:
                      values.contextConfigs.assistant.contactOptions.messenger,
                    email: values.contextConfigs.assistant.contactOptions.email,
                    sms: values.contextConfigs.assistant.contactOptions.sms,
                  },
                },
                cart: {
                  isActive: values.contextConfigs.cart.isActive,
                },
                offers: {
                  isActive: values.contextConfigs.offers.isActive,
                },
              },
              bgStartColor: values.bgStartColor,
              bgEndColor: values.bgEndColor,
              blockedUrls: values.blockedUrls,
              hideCocaBranding: values.hideCocaBranding,
              autoTranslate: values.autoTranslate,
              zIndex: values.zIndex,
              logoUrl: values.logoUrl,
              alignment: values.alignment,
              trackingPixels: {
                gaPixel: values.trackingPixels.gaPixel,
                fbPixel: values.trackingPixels.fbPixel,
              },
            },
          };
          updateWidgetMutation({
            id: { id: values.id },
            config: commonConfig,
          });
        }}
      >
        {(props) => {
          return (
            <PageViewContainer
              title={getTitle()}
              subTitle="Corner Widget"
              action={
                <div className="flex">
                  {props.dirty ? (
                    <Button
                      type="primary"
                      icon={<RiEyeLine />}
                      onClick={() => props.submitForm()}
                    >
                      Save Settings
                    </Button>
                  ) : (
                    <Button
                      className="mr-2"
                      type="ghost"
                      onClick={() => {
                        if (workspace.data)
                          window.open(`https://${workspace.data.storeUrl}`);
                      }}
                      icon={<RiEyeLine />}
                    >
                      View it on my store
                    </Button>
                  )}
                </div>
              }
            >
              <div className="flex w-full h-full ">
                <div className="desktop:max-w-xl mobile:w-full h-full flex-shrink-0 flex-grow px-8 overflow-y-auto">
                  <Switch>
                    <Route
                      path={`${path}/look-and-feel/`}
                      render={() => <LookAndFeel {...props} />}
                    />
                    <Route
                      path={`${path}/preferences/`}
                      render={() => <Preferences {...props} />}
                    />
                    <Route
                      path={`${path}/assistant/`}
                      render={() => <Assistant {...props} />}
                    />
                    <Route path={`${path}`}>
                      <Redirect to="/widgets/corner-widget/look-and-feel/" />
                    </Route>
                  </Switch>
                </div>
                <div className="flex-grow flex-wrap h-full bg-gray-100 border-l mobile:hidden desktop:flex items-center justify-center overflow-y-auto">
                  <div className="flex w-full items-center justify-center">
                    <div className="transform scale-80">
                        <CornerWidgetPreview
                          state="open"
                          bgStartColor={props.values.bgStartColor}
                          bgEndColor={props.values.bgEndColor}
                          shape="circle"
                          size="large"
                          viewState="assistant"
                          foregroundColor="light"
                          hideCocaBranding={
                            workspace.data?.feature_flag?.apps?.supportcorner?.remove_corner_branding
                          }
                          hideSidebar={true}
                          search={props.values.contextConfigs.assistant.search}
                          langObject={data?.widgets_cornerwidget[0].corner_lang}
                          defaultLang={
                            data?.workspaces[0].source.default_language
                          }
                          introEmoji={
                            props.values.contextConfigs.assistant.introEmoji
                          }
                          contactOptions={
                            props.values.contextConfigs.assistant.contactOptions
                          }
                        />
                    </div>
                  </div>
                  <div className="text-sm max-w-2xl text-center text-gray-500">
                    The content here is just for demo purposes. This preview is
                    also scaled down to 80% of the original size. Visit your
                    store to view the exact widget.
                  </div>
                </div>
              </div>
            </PageViewContainer>
          );
        }}
      </Formik>
    );
  else return <LoadingSpinner />;
};

export default CornerWidgetPage;
