import React, { useState } from "react";
import { useQuery } from "react-query";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import Button from "../../../../components/Button";
import PricingModal from "../../../../components/PricingModal";
import { RiCoinsFill } from "react-icons/ri";
import { useGraphQl } from "../../../../GraphqlClient";
import { useWorkspace } from "../../../../WorkspaceContext";
import { queryBillingInfo } from "../../../../api/billing";

export default function Billing(props) {
  const graphQL = useGraphQl();
  const workspace = useWorkspace();
  const { data } = useQuery("queryBillingInfo", () =>
    graphQL(queryBillingInfo, {
      appId: `${1}`,// hardcoded app id a temporary thing since we only have one app right now
      sourceId: `${workspace.data && workspace.data.sourceid}` 
    })
  );
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const AddonCard = (addonProps) => {
    return (
      <div className="flex justify-between border items-center rounded-md max-w-4xl p-6 mt-3">
        <div className="flex items-center pr-3">
          <div className="flex items-baseline flex-grow-0 text-primary-500 mr-7 text-4xl">
            {addonProps.price}{" "}
            <span className="text-xs text-gray-400">USD</span>
          </div>
          <div>
            <div className="mb-3">
              <h3 className="font-semibold leading-3 ">{addonProps.title}</h3>
              <small className="text-gray-400 text-xs">
                {" "}
                {addonProps.subText}
              </small>
            </div>
            <p>{addonProps.description}</p>
          </div>
        </div>
        <Button className="flex-shrink-0" type="outline" icon={<RiCoinsFill />}>
          {" "}
          Purchase Addon
        </Button>
      </div>
    );
  };

  return (
    <div className="px-8 py-4">
      <CustomiserUnit
        title="Your current plan"
        description="Upgrade or downgrade to any plan we offer using the settings given below."
      >
        <div className="flex items-center justify-between border rounded-md max-w-2xl p-6">
          {data && (
            <p>
              Current Plan : <b>{data.billingInfo?.currentPlan?.plan_display_name}</b>
            </p>
          )}
          <Button type="primary" onClick={() => setIsBillingModalOpen(true)}>
            {" "}
            Change Plan
          </Button>
        </div>
      </CustomiserUnit>
      {/* <CustomiserUnit
        title="One time purchase addons"
        description="We understand sometimes you don't want to pay a reccuring amount just to get one feature in a higher plan. In that case we offer following features for a one time amount. These options are only available in the free plan because these addons are included in the other plan's subscription amount by default"
      >
        <AddonCard
          title="Remove Branding"
          price={20}
          description="This addon will give you the ability to remove supportCorner branding from both faq page and the widget"
          subText="Included in pro plan"
        />
      </CustomiserUnit> */}
      <PricingModal
        isOpen={isBillingModalOpen}
        onClose={() => setIsBillingModalOpen(false)}
      />
    </div>
  );
}
