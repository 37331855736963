import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import Select from "@atlaskit/select";
import Button from "../../components/Button";
import { queryWidgetSettings, updateWidgetSettings } from "../../api/widgets";
import { updateSourceDetails } from "../../api/workspace";
import { useGraphQl } from "../../GraphqlClient";
import { useWorkspace } from "../../WorkspaceContext";
import {langOptions} from "../../variables"

export default function LanguageSelector(props) {
  const workspace= useWorkspace();
  const [languageCode, setlanguageCode] = useState("en");
  const graphQL = useGraphQl();
  const { data } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
  };
  const updateOnboarding = async () => {
    await graphQL(updateSourceDetails, {
      sourceId: { id: workspace.data.sourceid },
      sourceInput: {
        onboarding_progress: 100,
        default_language: languageCode,
      },
    });
  };

  const [updateWidgetMutation] = useMutation(updateWidget);
  const [updateOnboardingProgressMutation] = useMutation(updateOnboarding, {
    onSuccess: () => {
      window.location.href = "/assistant/faq/page/";
    },
  });

  const handleSubmit = async () => {
    let cornerLang= await require(`../../i18n/default-values/corner-widget/${languageCode}.json`);
    let faqLang= await require(`../../i18n/default-values/faq-page/${languageCode}.json`);

    updateWidgetMutation({
      id: { id: data && data.widgets_cornerwidget[0].id },
      config: {
        faq_lang: {[`${languageCode}`] :faqLang},
        corner_lang:{[`${languageCode}`]: cornerLang},
      },
    });
    updateOnboardingProgressMutation();
  };

  return (
    <div className="desktop:flex mobile:block items-center h-full w-full mt-8">
      <div className="p-8 flex-shrink flex-grow max-w-xl">
        <div className="mb-8">
          <h1 className="font-bold text-primary-600 mb-3">
            Select your default language
          </h1>
          <p>
            Based on this default language the text in the copy will be
            initialised.
          </p>
        </div>
        <div className="max-w-s mb-8">
          {data && (
            <Select
              value={langOptions.find((option) => option.value === languageCode)}
              onChange={(e) => {
                setlanguageCode(e.value);
              }}
              className="w-48 mr-2"
              classNamePrefix="react-select"
              options={langOptions}
            />
          )}
        </div>
        <div className="flex justify-end px-8">
          <Button type="primary" onClick={() => handleSubmit()}>
            Save & set it live
          </Button>
        </div>
      </div>
    </div>
  );
}
