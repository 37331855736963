/* -------------- Queries ---------- */

export const queryWorkspaceProps = `
query queryWorkspaceProps  {
 
  feature_access {
    feature_flag
  }
  workspaces {
    accountid
    id
    userid
    sourceid
    user {
      email
      full_name
    }
    source {
      source_domain
      onboarding_progress
      default_language
    }
  }
}

`;


/* --------------- Mutations ------------------- */
export const updateSourceDetails = `
  mutation updateSourceDetails ($sourceId:sources_pk_columns_input!, $sourceInput:sources_set_input){
    update_sources_by_pk(pk_columns: $sourceId, _set:$sourceInput) {
      id
    }
  }
`;