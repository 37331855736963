import React, { Children } from "react";
import { RiLoader2Line } from "react-icons/ri";

const LoadingSpinner = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className=" animate-spin text-5xl text-primary-500">
        <RiLoader2Line />
      </div>
    </div>
  );
};

export default LoadingSpinner;
