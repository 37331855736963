import React from "react";
import { useQuery } from "react-query";
import { queryWorkspaceProps } from "./api/workspace";
import { useGraphQl } from "./GraphqlClient";
import { useAuth } from "./AuthenticationContext";

/* WorkspaceContext's primary responsibility is to fetch the intial 
 * data needed to render the workspce like feature flag, account, source and user ids.
 * And provide this global context to all the branches in the app tree
 * App will not even render without this data.
 */

const defaultWorkspaceProps = {isAuthorised: false, status: 'loading', data: {}};
const WorkspaceContext = React.createContext(defaultWorkspaceProps);

const WorkspaceProvider = (props) => {
  const graphQL = useGraphQl();
  const authState = useAuth();

  const { isLoading, isError, data, error } = useQuery("queryWorkspaceProps", () =>
  {
  return graphQL(queryWorkspaceProps);
  },{enabled:authState.token ? true : false}
  );
  
  if(isError) {
    window.Rollbar.error("Error in WorkspaceContext query", error);
  }

  let wsdata = {};

  if (data)
  {
    const [workspace] = data.workspaces
    wsdata = {
      data: {
        accountid: workspace.accountid,
        userId: workspace.userid,
        feature_flag: data.feature_access[0].feature_flag,
        sourceid: workspace.sourceid,
        storeUrl: workspace.source.source_domain,
        onboarding_progress: workspace.source.onboarding_progress,
        user:workspace.user,
        default_language:workspace.source.default_language,
      },
    };};
  return <WorkspaceContext.Provider value={wsdata} {...props} />;
};

const useWorkspace = () => React.useContext(WorkspaceContext);

export { WorkspaceProvider, useWorkspace };
