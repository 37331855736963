import React, { useState } from "react";
import Button from "./Button"

{/* type Props = { */}
{/*   type: */}
{/*     | "primary" */}
{/*     | "ghost" */}
{/*     | "link" */}
{/*     | "outline" */}
{/*     | "danger" */}
{/*     | "danger-ghost" */}
{/*     | "danger-link" */}
{/*     | "danger-outline"; */}
{/*   disabled?: boolean; */}
{/*   className?: string; */}
{/*   icon?: React.ReactNode; */}
{/*   animate?: boolean; */}
{/*   turnOffAutoClose?: boolean; */}
{/*   children?: any; */}
{/*   triggerText?: any; */}
{/*   alignment?:"right"|"left" */}
{/* }; */}

const Dropdown = ({
  type,
  icon,
  children,
  className,
  animate,
  disabled,
  triggerText,
  alignment,
  turnOffAutoClose
}) => {
  const [isVisible, setIsVisisble] = useState(false);

  // Closes the popup when click outside
  window.onclick = (event) => {
    if (!turnOffAutoClose && isVisible === true && !event.target.matches(".dropbutton")) {
      setIsVisisble(false);
    }
  };
  return (
    <div className="dropbutton relative">
      <Button type={type} disabled={disabled} className={`dropbutton ${className}`} onClick={()=>setIsVisisble(!isVisible)} icon={icon} animate={animate}>
       {triggerText && <span className="dropbutton">{triggerText}</span>}
      </Button>
        <div
          id="myDropdown"
          className={`${!isVisible && "hidden"} w-max desktop:max-w-xl mobile:max-w-sm absolute top-full rounded-md shadow-md border border-gray-300 p-4 z-30 bg-white mt-1 ${alignment === "right" && "right-0"}`}
        >
          {children}
        </div>
    </div>
  );
};

export default Dropdown;
