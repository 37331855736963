import React, { useState, useEffect } from "react";
import LauncherPreview from "../widgets/Previews/LauncherPreview";
import { useQuery, useMutation } from "react-query";
import Textfield from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import Button from "../../components/Button";
import CustomiserUnit from "../../components/CustomiserUnit";
import { queryWidgetSettings, updateWidgetSettings } from "../../api/widgets";
import { useGraphQl } from "../../GraphqlClient";
import { useWorkspace } from "../../WorkspaceContext";
import { RiAlarmWarningFill } from "react-icons/ri";

export default function WidgetCreation(props) {
  const workspace= useWorkspace();
  const [text, setText] = useState("Help Desk");
  const [onClick, setOnClick] = useState("faq-page");
  const graphQL = useGraphQl();
  const { data } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
  };

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      props.history.push(`/get-started/3/`);
    },
  });

  const handleSubmit = () => {
    const sideTab = {
      ...data.widgets_cornerwidget[0].launcher_config.sideTab,
      text: text,
      onClick: onClick,
    };

    updateWidgetMutation({
      id: { id: data && data.widgets_cornerwidget[0].id },
      config: {
        launcher_config: {
          ...data.widgets_cornerwidget[0].launcher_config,
          sideTab: sideTab,
        },
        common_config: {
          ...data.widgets_cornerwidget[0].common_config,
          isActive: true,
        },
      },
    });
  };
  const OnClickOptions = [
    { label: "Open widget", value: "assistant" },
    { label: "Open FAQ page", value: "faq-page" },
  ];

  useEffect(() => {
    if (data) {
      setText(data.widgets_cornerwidget[0].launcher_config.sideTab.text);
      setOnClick(data.widgets_cornerwidget[0].launcher_config.sideTab.onClick);
    }
  }, [data]);

  return (
    <div className="desktop:flex mobile:block items-center h-full w-full mt-8">
      <div className="p-8 flex-shrink flex-grow max-w-xl">
        <div className="mb-6">
          <h1 className="font-bold text-primary-600 mb-3">
            Customize Launcher Tab
          </h1>
          <p>
            When a user clicks on this launcher tab the widget will open. More
            customisation options are also available for this but for now lets
            edit its text
          </p>
        </div>
        {data && (
          <div className="max-w-s mb-8">
            <CustomiserUnit
              title="Tab Text"
              description="Define the text that should be displayed on the tab"
            >
              <Textfield
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
                name="basic"
              />
              {text.length > 20 && (
                <small className="text-red-500 flex items-center">
                  <RiAlarmWarningFill />
                  <span className="ml-2">
                    Exceeded the recommended character length
                  </span>
                </small>
              )}
            </CustomiserUnit>
            <CustomiserUnit
              title="On Click Action"
              description="Select what happens when the side-tab is clicked"
            >
              <Select
                value={OnClickOptions.find(
                  (option) => option.value === onClick
                )}
                onChange={(e) => setOnClick(e.value)}
                className="w-64"
                classNamePrefix="react-select"
                options={OnClickOptions}
                placeholder="Select widget position"
              />
            </CustomiserUnit>
          </div>
        )}
        <div className="flex justify-end px-8">
          <Button type="primary" onClick={() => handleSubmit()}>
            Continue
          </Button>
        </div>
      </div>
      <div className="flex-grow h-full flex">
        {data && (
          <LauncherPreview
            cornerButtonConfig={
              data.widgets_cornerwidget[0].launcher_config.cornerButton
            }
            sideTabConfig={{
              ...data.widgets_cornerwidget[0].launcher_config.sideTab,
              text: text,
            }}
            alignment={data.widgets_cornerwidget[0].common_config.alignment}
            themeColor={data.widgets_cornerwidget[0].common_config.bgEndColor}
          />
        )}
      </div>
    </div>
  );
}
