import React from "react";
import CloseButton from "./CloseButton";

const Header = (props) => {
  return (
    <div className="coca-d-flex coca-align-items-center coca-cowi-logo-header">
      <div className="coca-d-col coca-cowi-store-logo">
      </div>
      <div className="coca-d-flex coca-align-items-center">
        {/* <select
          className="coca-cowi-tiny-button coca-justify-content-end"
          name="language"
          id="language"
          value="en"
          ref={(node) => {
            if (node) {
              node.style.setProperty(
                "border-color",
                `${
                  props.foregroundColor === "light"
                    ? "rgba(255,255,255,1)"
                    : "rgba(0,0,0,.6)"
                }`,
                "important"
              );
              node.style.setProperty(
                "color",
                `${
                  props.foregroundColor === "light"
                    ? "rgba(255,255,255,1)"
                    : "rgba(0,0,0,.6)"
                }`,
                "important"
              );
            }
          }}
        >
          <option value="en">En</option>
          <option value="es">Es</option>
          <option value="a">Ar</option>
          <option value="ml">Ml</option>
              </select> */}

        <div className="coca-cowi-opened-close-button coca-flex-grow-0 coca-d-col ">
          <CloseButton foregroundColor={props.foregroundColor} />
        </div>
      </div>
    </div>
  );
};

export default Header;
