import React from "react";
import { RiLoader5Fill } from "react-icons/ri";

{/* type Props = { */}
{/*   type: */}
{/*     | "primary" */}
{/*     | "ghost" */}
{/*     | "link" */}
{/*     | "link-thin" */}
{/*     | "outline" */}
{/*     | "danger" */}
{/*     | "danger-ghost" */}
{/*     | "danger-link" */}
{/*     | "danger-outline"; */}
{/*   onClick?: any; */}
{/*   disabled?: boolean; */}
{/*   className?: string; */}
{/*   icon?: React.ReactNode; */}
{/*   animate?: boolean; */}
{/*   loading?: boolean; */}
{/*   children?: React.ReactNode; */}
{/* }; */}

const Button = ({
  type,
  onClick,
  icon,
  children,
  className,
  animate,
  disabled,
  loading,
}) => {
  const getBtnStyle = () => {
    switch (type) {
      case "primary":
        return "bg-primary-500 hover:bg-primary-600 text-white font-bold ";
      case "ghost":
        return "bg-gray-400  text-primary-600 bg-opacity-25 hover:bg-opacity-25 hover:bg-gray-500 font-bold ";
      case "link-thin":
        return "text-primary-600 hover:text-primary-500 ";
      case "link":
        return "text-primary-600 hover:text-primary-500 font-bold";
      case "outline":
        return "border border-primary-500 text-primary-500 hover:text-primary-600 hover:border-primary-600 font-bold ";
      case "danger":
        return "bg-red-500 hover:bg-red-600 text-white font-bold ";
      case "danger-ghost":
        return "bg-red-200  text-red-600 bg-opacity-25 hover:bg-opacity-25 hover:bg-red-300 font-bold ";
      case "danger-link":
        return "text-red-600 hover:text-red-500 font-bold";
      case "danger-outline":
        return "border border-red-500 text-reed-500 hover:text-red-600 hover:border-red-600 font-bold ";
      default:
        return "bg-primary-500  text-white font-bold";
    }
  };

  return (
    <button
      onClick={onClick}
      className={`flex desktop:py-2 desktop:px-4 mobile:py-2 mobile:px-2 items-center  rounded-md transition duration-200 ease-in-out cursor-pointer focus:outline-none focus:shadow-none ${getBtnStyle()} ${className} ${
        animate && "animate-breathe"
      } ${disabled && "pointer-events-none opacity-50 cursor-not-allowed"}`}
    >
      {(icon || loading) && (
        <span
          className={`desktop:mr-2 mobile:mr-1 text-xl ${
            loading === true && "animate-spin"
          }`}
        >
          {loading ? <RiLoader5Fill /> : icon}{" "}
        </span>
      )}
      <span className="flex-shrink-0">{children}</span>
    </button>
  );
};

export default Button;
