import React from 'react'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from 'react-router-dom'

import  CornerWidget from './CornerWidget';
import Launcher from "./Launcher";

export default function WidgetsRouter(){ 
  let { path } = useRouteMatch();


  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Redirect to="/widgets/corner-widget" />
      </Route>
      <Route path={`${path}/corner-widget`} component={CornerWidget} />
      <Route path={`${path}/launchers`} component={Launcher} />
    </Switch>
  );
}
