import React from "react";
import { RiLoader2Line } from "react-icons/ri";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    //any extra debug info
    // window.Rollbar.error("ErrorBoundary caught an error: ", error);
  }

  render() {
    const { hasError } = this.state;
    {
      if (hasError) {
        return (
          <div className="w-screen h-screen flex items-center justify-center">
            <div className="text-center">
              <div className="mb-5 flex items-center justify-center">
                <img
                  src="https://assets.cornercart.io/coca-v2/images/onboard/error.png"
                  alt=""
                />
              </div>
              <h2 className="text-primary-500">Whooops, something just didn't go as planned.</h2>
              <p>
                Please do try logging in again, and if the error persists please do get in touch with us via chat.
              </p>
            </div>
          </div>
        );
      }
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
