import firebase from 'firebase/app';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  // authDomain: 'xxx',
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_PROJECT_ID
  // storageBucket: 'xxx',
  // messagingSenderId: 'xxx',
})

export default firebase;
