

    const langOptions = [
        { label: "Arabic", value: "ar" },
        { label: "Danish", value: "da" },
        { label: "German", value: "de" },
        { label: "English", value: "en" },
        { label: "Espaniol", value: "es" },
        { label: "Finnish", value: "fi" },
        { label: "French", value: "fr" },
        { label: "Hindi", value: "hi" },
        { label: "Hungarian", value: "hu" },
        { label: "Italian", value: "it" },
        { label: "Japanese", value: "ja" },
        { label: "Korean", value: "ko" },
        { label: "Dutch", value: "nl" },
        { label: "Polish", value: "pl" },
        { label: "Portuguese", value: "pt-BR" },
        { label: "Swedish", value: "sv" },
        { label: "Thai", value: "th" },
        { label: "Ukrainian", value: "uk" },
        { label: "Chinese", value: "zh-CN" },
        { label: "Taiwanese", value: "zh-TW" },
    ];

export {langOptions}