import React,  { useState }  from "react";
import { ChromePicker } from "react-color";
import Popup from "@atlaskit/popup";
import {
  RiPaintFill,

} from "react-icons/ri";

const ColorPicker = (props) => {
  const [colorPickerVisibility, setColorPickerVisibility] = useState(false);
  const [colorObject, setColorObject] = useState({r:0,b:0,g:0});
  const calculateForeground = (rgb) => {
    // Based on the R, G, B values foreground color is calculated (either black or white)
    const foreground =
      rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186 ? "rgba(0, 0, 0, 0.6)" : "white";
    return foreground;
  };
  return (
    <Popup
    isOpen={colorPickerVisibility}
    onClose={() => setColorPickerVisibility(false)}
    content={() => (
      <ChromePicker
        color={props.color}
        disableAlpha
        onChange={(color) =>{
          setColorObject(color.rgb);
          props.onChange(color);
        }}
      />
    )}
    trigger={(triggerProps) => (
      <button
        {...triggerProps}
        onClick={() => setColorPickerVisibility(!colorPickerVisibility)}
        style={{ background: props.color }}
        className="w-8 h-8 rounded-md flex items-center justify-center"
      >
        <div
          style={
            { color:`${calculateForeground(colorObject)}` }
          }
        >
          <RiPaintFill />
        </div>
      </button>
    )}
  />
  );
};

export default ColorPicker;
