import React, { useState, useEffect } from "react";
import { useWorkspace } from "../../WorkspaceContext";
import { useGraphQl } from "../../GraphqlClient";
import { useMutation } from "react-query";
import { Redirect } from "react-router-dom";
import { activateCharge } from "../../api/billing";
import toaster from "toasted-notes";

function PaymentsLoader() {
  const [invalidChargeID,setInvalidChargeID]=useState(false);
  const graphQL = useGraphQl();
  const urlParams = new URLSearchParams(window.location.search)
  const chargeId = urlParams.get("charge_id");

  const chargeActivation = async () => {
    const status = await graphQL(activateCharge , {
      chargeId: chargeId,
    });
    return status
  };

  const [chargeActivationMutation] = useMutation(
    chargeActivation,
    {
      onError: (err) => {
        console.log("error", err);
        toaster.notify("Redirection Failed, please try logging in again", {
          duration: 4000,
        })
      },
      onSuccess: (data) => {
        console.log("return Data", data)
        if(data.activateCharge.billingStatus==="active")
        window.location.href="/"
        else
        toaster.notify("Redirection Failed", {
          duration: 2000,
        });
      },
    }
  );

  useEffect(() => {
    if(chargeId)
    chargeActivationMutation()
    else
    setInvalidChargeID(true)
  }, []);

  return (
    <>
      <div className="w-screen h-screen flex items-center justify-center bg-primary-100">
        <div className="text-center bg-white shadow-xl p-8 rounded-lg">
          <h1 className="text-6xl animate-bounce -mb-7">💵</h1>
          <h1 className="text-7xl leading-3 mb-14">🤲</h1>
          <h1 className="text-primary-500 font-bold mb-4">Updating Payment Status...</h1>
          <p className="max-w-lg">
            It might take a few seconds to redirect you back to the app. If you
            are stuck here for more that 5 seconds please do relogin to app by
            clicking our app icon in your shopify dashboard
          </p>
          {invalidChargeID && 
          <p className="mt-3 text-sm text-red-600"><b>Error:</b> No charge ID found in the URL</p>
          }
        </div>
      </div>
    </>
  );
}

export default PaymentsLoader;
