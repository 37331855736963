import React, { useState } from "react";
import CategoryHeader from "./CategoryHeader";
import DesktopCategoryNav from "./DesktopCategoryNav";
import HeaderIcon from "./HeaderIcon";
import SectionNav from "./SectionNav";
import Section from "./Section";
import MobileSideBar from "./MobileSideBar";
import ReviewAsker from "./ReviewAsker";

const Layout = (props) => {
  const [mobileSideBarVisibility, setMobileSideBarVisibility] = useState(false);
  return (
    <>
      <div className=" backdrop-image grid bg-gradient-to-b from-white to-bg-gradient w-full h-screen grid-rows-primary-row  mobile:grid-cols-primary-mob-col desktop:grid-cols-primary-desk-col ">
        <HeaderIcon sidebarTrigger={() => setMobileSideBarVisibility(true)} />
        <CategoryHeader />
        <DesktopCategoryNav />
        <Section navigationBar={<SectionNav />} pageView={props.children} />
      </div>
      <MobileSideBar
        isVisible={mobileSideBarVisibility}
        onClose={() => setMobileSideBarVisibility(false)}
      />

      <ReviewAsker/>
    </>
  );
};

export default Layout;
