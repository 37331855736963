import React, { useState } from "react";
import {
  RiChatSmile3Line,
  RiChatSmile3Fill,
  RiBookMarkLine,
  RiBookMarkFill,
} from "react-icons/ri";
import { NavLink,useRouteMatch } from "react-router-dom";

{/* interface BtnProps { */}
{/*   title: string; */}
{/*   normalIcon: React.ReactNode; */}
{/*   focusedIcon: React.ReactNode; */}
{/*   link: string; */}
{/* } */}


const CategoryNavBtn = (props) => {
  let match = useRouteMatch(props.link);
  const isSelected = match?.path === props.link;
  return (
    <NavLink
      to={props.link}
      strict
    >
      <div className="text-primary-600 flex flex-col items-center justify-center py-5 cursor-pointer">
        <span className="text-xl">
          {isSelected ? props.focusedIcon : props.normalIcon}
        </span>
        <small className={`text-xs ${isSelected ? "font-bold" : ""}`}>
          {props.title}
        </small>
      </div>
    </NavLink>
  );
};

const CategoryNavButtons = () => {
  return (
    <div className="flex flex-col">
      <CategoryNavBtn
        title="Assitant"
        normalIcon={<RiBookMarkLine size="22px" />}
        focusedIcon={<RiBookMarkFill size="22px" />}
        link="/assistant/"
      />
      <CategoryNavBtn
        title="Widgets"
        normalIcon={<RiChatSmile3Line size="22px" />}
        focusedIcon={<RiChatSmile3Fill size="22px" />}
        link="/widgets/"
      />
    </div>
  );
};

export default CategoryNavButtons;
