/* --------------- Queries ------------------- */
export const queryAllAssistantArticles = `
  query queryAllAssistantArticles{
    assistant_articles {
      id
      title
      statistics
      is_published
      is_featured
      lang
      body
      categoryid
      position_rank
    }
    assistant_categories{
      id
      name
      icon_url
      meta
      position_rank
    }
      accounts{
    id
  }
  }
`;

export const queryAssistantArticle = `
query queryAssistantArticle($article_id: bigint!) {
  assistant_articles(
    where: { id: { _eq: $article_id } }
  ) {
   id
      title
      statistics
      is_published
      is_featured
      lang
      body
      categoryid
      updated_at
      position_rank
  }
  assistant_categories{
      id
      name
      icon_url
      meta
      position_rank
    }
}
`;
  ;
export const queryArticleCategories = `
query queryArticleCategories($categoryid: bigint!) {
  assistant_articles(
    where: { categoryid: { _eq: $categoryid } }
  ) {
   id
      title
      statistics
      is_published
      is_featured
      lang
      body
      categoryid
      updated_at
      position_rank
  }
   assistant_categories (
    where: { id: { _eq: $categoryid } }
  ){
      id
      name
      icon_url
      meta
      position_rank
      show_in_widget
      show_in_page
    }
}
`;
  ;

/* --------------- Mutations ------------------- */

export const insertAssistantArticle = `
  mutation insertAssistantArticle ($articleInput:assistant_articles_insert_input!){
    insert_assistant_articles_one(object: $articleInput) {
      id
    }
  }
`;

export const updateAssistantArticle = `
  mutation updateAssistantArticle ($articleId:assistant_articles_pk_columns_input!, $articleInput:assistant_articles_set_input){
    update_assistant_articles_by_pk(pk_columns: $articleId, _set:$articleInput) {
      id
      is_published
      categoryid
      title
      body
    }
  }
`;

export const bulkUpdateAssistantArticle = `
mutation bulkCategoryUpdateAssistantArticle($idArray: [bigint!]!,$newCategory:assistant_articles_set_input) {
  update_assistant_articles(_set:$newCategory, where:{_and:{id:{_in:$idArray}}}) {
     affected_rows
    }
}
`;

export const deleteAssistantArticle = `
  mutation deleteAssistantArticle ($articleId:bigint!){
    delete_assistant_articles_by_pk(id: $articleId) {
      id
    }
  }
`;

export const bulkDeleteAssistantArticle = `
mutation bulkDeleteAssistantArticle($idArray: [bigint!]!) {
  delete_assistant_articles(where:{_and:{id:{_in:$idArray}}})
  {
    affected_rows
  }
}
`;


export const insertAssistantCategory = `
mutation insertAssistantCategory ($categoryInput: assistant_categories_insert_input!){
  insert_assistant_categories_one(object: $categoryInput) {
    id
  }
}
`;
export const updateAssistantCategory = `
mutation updateAssistantCategory ($categoryId: assistant_categories_pk_columns_input!, $categoryInput:assistant_categories_set_input){
  update_assistant_categories_by_pk(pk_columns: $categoryId, _set:$categoryInput) {
    id
  }
}
`;

export const deleteAssistantCategory = `
  mutation deleteAssistantCategory ($categoryId:bigint!){
    delete_assistant_categories_by_pk(id: $categoryId) {
      id
    }
  }
`;
