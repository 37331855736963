import React from "react";


const CornerButtonPreview = (props) => {
  const calculateForeground = (rgb) => {
    let rgbArray = rgb
    .substring(rgb.indexOf("(") + 1, rgb.length - 1)
    .split(",")
      .map(Number);
    let foreground =
      rgbArray[0] * 0.299 + rgbArray[1] * 0.587 + rgbArray[2] * 0.114 > 186
        ? "dark"
        : "light";
    return foreground;
  };

  const cornerButtonBorderRadius = () => {
    switch (props.cornerButtonConfig.shape) {
      case "circle":
        return "50%";
      case "square":
        return "2px";
      case "rounded-box":
        return "4px";
      case "app-icon":
        return "8px";
    }
  }
  const cornerButtonSize = () => {
    switch (props.cornerButtonConfig.size.desktop) {
      case "small":
        return "20px";
      case "medium":
        return "25px";
      case "large":
        return "30px";
    }
  }
  const cornerButtonIconSize = () => {
    switch (props.cornerButtonConfig.size.desktop) {
      case "small":
        return "10px";
      case "medium":
        return "12px";
      case "large":
        return "15px";
    }
  }

  const cornerButtonStyleValues = {
    background: props.themeColor,
    left: props.alignment === "left" ? "0" : "none",
    right: props.alignment === "right" ? "0" : "none",
    bottom: `${props.cornerButtonConfig.verticalPositioning}%`,
    borderRadius: `${cornerButtonBorderRadius()}`,
    height: `${cornerButtonSize()}`,
    width: `${cornerButtonSize()}`,
  };
  const sideTabStyleValues = {
    background: props.themeColor,
    left: props.alignment === "left" ? "0" : "none",
    right: props.alignment === "right" ? "0" : "none",
    bottom: `${props.sideTabConfig.verticalPositioning}%`,
  };
  const sideTabTextStyleValues = {
    writingMode: "vertical-lr",
    transform: "rotate(180deg)",
    color: calculateForeground(props.themeColor) === "light" ? "white" : "black",
  };


  return (
    <div className="w-full bg-white shadow-lg h-2/3 m-8 rounded-lg overflow-hidden flex flex-col ">
      <div className="w-full h-8 bg-primary-100 flex">
        <div className="flex h-full items-center">
          <div className="w-2 h-2 mr-2 ml-2 rounded-full bg-red-500" />
          <div className="w-2 h-2 mr-2 rounded-full bg-yellow-400" />
          <div className="w-2 h-2 mr-2 rounded-full bg-green-400" />
        </div>
        <div className="flex items-end ml-3">
          <div className="bg-white w-40 h-4/5 rounded-t-md" />
        </div>
      </div>
      <div className="flex border-b p-3 shadow-sm ">
<div className="w-5 h-5 mr-3 rounded-full border"/>
<div className="w-5 h-5 mr-3 rounded-full border"/>
<div className="flex-grow h-5 rounded-full border"/>
      </div>
      <div className="relative flex-grow">
        {props.cornerButtonConfig.isActive && (
          <div
            className={`absolute m-4 flex items-center justify-center shadow-md`}
            style={cornerButtonStyleValues}
          >
            {calculateForeground(props.themeColor) === "light" ? (
              <img
                style={{ width: cornerButtonIconSize() }}
                src={
                  "https://assets.cornercart.io/images/corner-widget/chat-selected-light.svg"
                }
                alt="Cart"
              />
            ) : (
              <img
                style={{ width: cornerButtonIconSize() }}
                src={
                  "https://assets.cornercart.io/images/corner-widget/chat-selected-dark.svg"
                }
                alt="Cart"
              />
            )}
          </div>
        )}
        {props.sideTabConfig.isActive && (
          <div
            className={`absolute flex flex-col items-center justify-center p-2 rounded-r-sm shadow-md`}
            style={sideTabStyleValues}
          >
            <p className="font-bold text-xs" style={sideTabTextStyleValues}>
              {props.sideTabConfig.text}
            </p>
            {calculateForeground(props.themeColor) === "light" ? (
              <img
                className="w-3 mt-2"
                src={
                  "https://assets.cornercart.io/images/corner-widget/chat-selected-light.svg"
                }
                alt="Cart"
              />
            ) : (
              <img
                className="w-3 mt-2"
                src={
                  "https://assets.cornercart.io/images/corner-widget/chat-selected-dark.svg"
                }
                alt="Cart"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CornerButtonPreview;
