import React, { useContext } from "react";
import Assistant from "./Assistant"
import Header from "./Header";


const Open = (props) => {

  const viewRenderComponent = () => {
    switch (props.viewState ) {
      case "assistant":
        return <Assistant {...props} />;
      // case "chat":
      //   return <Chat />;
      // case "cart":
      //   return <CartHome />;
      // case "faqList":
      //   return <FaqList />;
      // case "faqView":
      //   return <FaqView />;
      // case "offers":
      //   return <OffersHome />;
      // case "offerView":
      //   return <OfferView />;
    }
  };
    
      const findBgColor = () => {
        var RGBvalues = props.bgEndColor.substring(
          props.bgEndColor.indexOf("(") + 1,
          props.bgEndColor.length - 1
        );
        const bgColor = `rgba(${RGBvalues},0.75)`;
        return bgColor;
      };
    
    return (
      <div
        className="coca-d-flex coca-cowi-opened "
        ref={node => {
          if (node)
            node.style.setProperty(
              "background",
              `linear-gradient(180deg, ${props.bgStartColor} 0%, ${props.bgEndColor}  100%)`,
              "important"
            );
        }}
      >
        <Header
          foregroundColor={props.foregroundColor}
          logoUrl={props.logoUrl}
        />
        <div className="coca-d-flex coca-flex-grow-1 coca-cowi-main-body-wrapper">

        <div>
            {!props.hideCocaBranding ? (
              <a
                ref={node => {
                  if (node)
                    node.style.setProperty(
                      "color",
                      `${
                        props.foregroundColor === "light"
                          ? "rgba(255,255,255,1)"
                          : "rgba(0,0,0,.6)"
                      }`,
                      "important"
                    );
                }}
                className={`coca-cowi-branding coca-text-center`}
                target="_blank"
              >
                Powered By <b>Corner</b>
              </a>
            ) : (
              <div className="coca-cowi-disabled-branding-spacer" />
            )}
          </div>
          
          <div
            className={`coca-d-flex coca-flex-grow-1 coca-cowi-main-content-area`}
          >
            {viewRenderComponent()}
            
          </div>
        </div>
      </div>
    );
};
export default Open;
