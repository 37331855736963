import React, { useState } from "react";
import toaster from "toasted-notes";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import Button from "../../../../components/Button";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import { useWorkspace } from "../../../../WorkspaceContext";

import {
  RiFileCopy2Fill,
  RiArrowRightUpFill,
  RiPlayCircleFill,
  RiLandscapeFill,
} from "react-icons/ri";

const FaqPage = (props) => {
  const workspace= useWorkspace();
  const [isTutModalOpen, setIsTutModalOpen] = useState(false);
  const [isVidModalOpen, setIsVidModalOpen] = useState(false);

  return (
    <div className="desktop:mx-11 mobile:mx-4 p-11 bg-primary-100 bg-opacity-50 rounded-lg mt-8 overflow-y-auto ">
      <div className="mobile:block desktop:flex items-center">
        <button
          onClick={() => setIsVidModalOpen(true)}
          className="relative text-center py-14 px-16 rounded-lg shadow-lg bg-gradient-to-r from-primary-400 to-primary-600 text-primary-200 transform scale-100 hover:scale-110  duration-700  m-7 hover:shadow-xl hover:text-white transition-all"
        >
          <div className="absolute -bottom-5 left-1/2 py-7 px-12 shadow-lg bg-gradient-to-r from-primary-200 to-primary-500  transform -skew-y-12 opacity-90" />
          <div className="absolute -top-5 -left-3 py-4 px-10 shadow-lg bg-gradient-to-r from-primary-200 to-primary-500 transform -skew-y-12 opacity-90" />
          <h1 className="text-3xl text-center flex justify-center ">
            <RiPlayCircleFill />
          </h1>
          <p className="">Watch Video</p>
        </button>
        <div>
          <h1 className="font-bold mb-4 text-primary-600">
            Add FAQ Page to your store
          </h1>
          <p className=" text-gray-700">
            Watch this tutorial video or click on the link below to learn how to add
            the link to the FAQ page to your Shopify store (in the main menu)
          </p>
            <div className="mt-3">
              <Button
                type="primary"
                className="mr-3 mt-2"
                onClick={() => setIsTutModalOpen(true)}
              >
                Learn how to add FAQ page to your store
              </Button>
            </div>
        </div>
      </div>
      <ModalTransition>
        {isTutModalOpen && (
          <Modal
            width={840}
            actions={[
              {
                text: "Close",
                onClick: () => {
                  setIsTutModalOpen(false);
                },
              },
            ]}
            onClose={() => setIsTutModalOpen(false)}
            heading="Add FAQ page to Shopify store"
          >
            <div>
              <p>
   In this short step-by-step tutorial, we will see how to setup the link to your brand new FAQ page in your Shopify store. 

As you can see, the FAQ page is already ready for you at the FAQ Page URL below. You can see it by copying the URL below and pasting in a new browser tab.

All we need to do is add a link to the FAQ Page to the navigation menu in your Shopify store. So let's start!
              </p>
              <div className="mt-14">
                <h2 className="font-bold mb-1 text-primary-600">
                  Step 1 : Copy FAQ page URL
                </h2>
                <p>
                  Copy the following FAQ page url to your clipboard by clicking
                  on it
                </p>
                <Button
                  icon={<RiFileCopy2Fill />}
                  type="ghost"
                  className="mt-5"
                  onClick={() => {
                    navigator.clipboard.writeText(props.url);
                    toaster.notify("URL copied ", {
                      duration: 1000,
                    });
                  }}
                >
                  {`${props.url}`}
                </Button>
              </div>

              <div className="mt-14">
                <h2 className="font-bold text-primary-600">
Step 2: Go to the "Navigations" setting in your Shopify theme
                </h2>
                <p>
                  Click the button below to visit the your store's navigations
                  admin page. (This page can be manually accesed by going to
                  your Shopify admin ▶ Online Store ▶ Navigations)
                </p>
                <Button
                  icon={<RiArrowRightUpFill />}
                  type="ghost"
                  className="mt-5"
                >
                  <a href={`https://${workspace.data.storeUrl}/admin/menus`}>
                    Go to Shopify Navigations Page
                  </a>
                </Button>
              </div>

              <div className="mt-14">
                <h2 className="font-bold text-primary-600">
                  Step 3 : Add the FAQ page link to one or more than one menus
                </h2>
                <p className="my-3">
                  <b>(i)</b> Select a menu to which you wish to add the link to
                  the FAQ page to
                </p>
                <p className="my-3">
                  <b>(ii)</b>Once you get inside, click the{" "}
                  <b>Add menu Item</b> button.
                </p>
                <img
                  src="https://assets.cornercart.io/coca-v2/images/tutorial/faq-page-add/add-menu.png"
                  width="75%"
                  alt=""
                />
                <p className="my-3">
                  <b>(iii)</b>Give a name and paste the link that we had copied
                  earlier to the link box as shown below. 
                  <b><i> IMPORTANT: You have to click on the link after pasting it for it to be accepted 🤷‍♂️</i></b>
                </p>
                <img
                  src="https://assets.cornercart.io/coca-v2/images/tutorial/faq-page-add/paste-link.png"
                  width="75%"
                  alt=""
                />

                <p className="my-3">
                  <b>(iv)</b>Press <b>SAVE button</b>.
                </p>
                <p className="my-3">
                  That's it! Your store visitors will now be able to see a link to the FAQ Page on your Shopify store 🙌
                </p>
              </div>
            </div>
          </Modal>
        )}
        {isVidModalOpen && (
          <Modal
            actions={[
              {
                text: "Close",
                onClick: () => {
                  setIsVidModalOpen(false);
                },
              },
            ]}
            onClose={() => setIsVidModalOpen(false)}
            heading="Add FAQ page to Shopify store"
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/8rw8z2a_5Eg"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default FaqPage;
