import React from "react";
import CustomiserUnit from "../../../components/CustomiserUnit";
import Select from "@atlaskit/select";
import { Checkbox } from "@atlaskit/checkbox";
import Textfield from "@atlaskit/textfield";
import {
  RiCheckboxBlankCircleFill,
  RiCheckboxBlankCircleLine,
} from "react-icons/ri";

const CornerButton = (props) => {
  const widgetStatus = [
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-green-400 mr-2 text-lg" />{" "}
          Active{" "}
        </div>
      ),
      value: true,
    },
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleLine className="flex-none text-red-600 mr-2 text-lg" />{" "}
          Deactivated{" "}
        </div>
      ),
      value: false,
    },
  ];
  const widgetShape = [
    { label: "Circle", value: "circle" },
    { label: "Square", value: "square" },
    { label: "Rounded square", value: "rounded-square" },
    { label: "App-icon", value: "app-icon" },
  ];
  const widgetSizes = [
    { label: "Small", value: "small" },
    { label: "Medium", value: "medium" },
    { label: "Large", value: "large" },
  ];
  const visibilityOptions = [
    { label: "Show", value: true },
    { label: "Hide", value: false },
  ];

  return (
    <>
      <CustomiserUnit
        title="Enable Corner Button"
        description="A small widget that appears on the bottom corner of your website "
      >
        <Select
          value={widgetStatus.find(
            (option) => option.value === props.values.cornerButton.isActive
          )}
          onChange={(e) =>
            props.setFieldValue("cornerButton.isActive", e.value)
          }
          className="w-48 mr-2"
          classNamePrefix="react-select"
          options={widgetStatus}
          placeholder="Turn on widget"
        />
      </CustomiserUnit>

      <CustomiserUnit
        disabled={!props.values.cornerButton.isActive}
        title="Visibility"
        description="Select whether the launcher should be shown in mobile/desktop "
      >
        <div className="flex items-center">
          <div>
            <label className="text-xs text-gray-700">Desktop</label>
            <Select
              value={visibilityOptions.find(
                (option) =>
                  option.value === props.values.cornerButton.visibility.desktop
              )}
              onChange={(e) =>
                props.setFieldValue("cornerButton.visibility.desktop", e.value)
              }
              className="w-32"
              classNamePrefix="react-select"
              options={visibilityOptions}
              placeholder="Size"
            />
          </div>
          <div className="ml-4">
            <label className="text-xs text-gray-700">Mobile</label>
            <Select
              value={visibilityOptions.find(
                (option) =>
                  option.value === props.values.cornerButton.visibility.mobile
              )}
              onChange={(e) =>
                props.setFieldValue("cornerButton.visibility.mobile", e.value)
              }
              className="w-32"
              classNamePrefix="react-select"
              options={visibilityOptions}
              placeholder="Size"
            />
          </div>
        </div>
        <div className="border rounded-lg mt-3 p-4 flex items-center justify-between">
            <Checkbox
              onChange={(e) => {
                props.setFieldValue(
                  "cornerButton.visibility.showOnProductPage",
                  e.target.checked
                );
              }}
              label="Show on product pages"
              name="checkbox-basic"
              defaultChecked={
                props.values.cornerButton.visibility.showOnProductPage
              }
            />
          <div className="border-r h-6 w-1" />
          <Checkbox
              onChange={(e) => {
                props.setFieldValue(
                  "cornerButton.visibility.showOnCollectionPage",
                  e.target.checked
                );
              }}
              label="Show on collection pages"
              name="checkbox-basic"
              defaultChecked={
                props.values.cornerButton.visibility.showOnCollectionPage
              }
            />
        </div>
      </CustomiserUnit>

      <CustomiserUnit
        disabled={!props.values.cornerButton.isActive}
        title="Button Shape"
        description="Select the shape of widget to match your theme"
      >
        <Select
          value={widgetShape.find(
            (option) => option.value === props.values.cornerButton.shape
          )}
          onChange={(e) => props.setFieldValue("cornerButton.shape", e.value)}
          className="w-64"
          classNamePrefix="react-select"
          options={widgetShape}
          placeholder="Select widget position"
        />
      </CustomiserUnit>

      <CustomiserUnit
        disabled={!props.values.cornerButton.isActive}
        title="Vertical Position"
        description="Define the vertical positioning of the launcher"
      >
        <Textfield
          defaultValue={props.values.cornerButton.verticalPositioning}
          onChange={(e) => {
            props.setFieldValue(
              "cornerButton.verticalPositioning",
              e.target.value
            );
          }}
          name="basic"
          type="number"
        />
      </CustomiserUnit>

      <CustomiserUnit
        disabled={!props.values.cornerButton.isActive}
        title="Size"
        description="Select the button's size in both mobile and desktop "
      >
        <div className="flex items-center">
          <div>
            <label className="text-xs text-gray-700">Desktop</label>
            <Select
              value={widgetSizes.find(
                (option) =>
                  option.value === props.values.cornerButton.size.desktop
              )}
              onChange={(e) =>
                props.setFieldValue("cornerButton.size.desktop", e.value)
              }
              className="w-32"
              classNamePrefix="react-select"
              options={widgetSizes}
              placeholder="Size"
            />
          </div>
          <div className="ml-4">
            <label className="text-xs text-gray-700">Mobile</label>
            <Select
              value={widgetSizes.find(
                (option) =>
                  option.value === props.values.cornerButton.size.mobile
              )}
              onChange={(e) =>
                props.setFieldValue("cornerButton.size.mobile", e.value)
              }
              className="w-32"
              classNamePrefix="react-select"
              options={widgetSizes}
              placeholder="Size"
            />
          </div>
        </div>
      </CustomiserUnit>
    </>
  );
};

export default CornerButton;
