import React, { useState, useEffect } from 'react'
import firebase from "./firebaseIni";
import "firebase/auth";
import "firebase/database";


/* AuthContext's primary responsibility is to supply
 * JWT tokens needed by the app to authenticate calls 
 * to our graphql endpoint. 
 */

const defaultData = {}
const AuthenticationContext = React.createContext(defaultData)


const AuthenticationProvider = (props) => {


  const urlParams = new URLSearchParams(window.location.search)
  const [authState, setAuthState] = useState({ status: 'loading', token: ""})

  // When the AuthenticationProvider component loads, we need to check
  // if the auth server has set the custom_token in the cookie. If so
  // we will use that to authenticate with firebase
  useEffect(() => {
    const customToken = urlParams.get("token");
    const tenantId = urlParams.get("tenant_id");

    window.Rollbar.configure({
      payload: {
        person: {
          id: tenantId, // required
          //email: ??
          // TODO can we get the accountID
          // and/or user email also here, passed via URL param?
        }
      }
    });
    window.Rollbar.info("Rollbar Init done...Tenant id..", tenantId);

    firebase.auth().tenantId = tenantId;
    if (customToken) {
      firebase.auth().signOut();
      firebase.auth().signInWithCustomToken(customToken).catch(function(err) {
        var errorCode = err.code;
        var errorMessage = err.message;
        window.Rollbar.error("Firebase auth error! ", errorMessage)
      });
      // one we authenticate, we remove the cookie, since from then on
      // firebase will take care of handling the tokens for us
    }

  }, [])


  // Register an observer function that should run when the auth status changes
  // Firebase will automatically call this function whenever there's a status change
  // We do this inside useEffect since this functon needs to be registered just once.
  useEffect(() => {
   const unsubscribe = firebase.auth().onIdTokenChanged(async user => {
      if (user) {
        const userEmail = urlParams.get("email");
        if(userEmail){
          user.updateEmail(userEmail).then(function() {
            //window.Rollbar.info("Firebase on auth state change: email update attempt");
          }).catch(function(error) {
            window.Rollbar.error("Firebase on auth state change: email update failed");
          });
        }
        const token = await user.getIdToken(true);
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];


        if (hasuraClaim) {
          setAuthState({ status: 'in', token:token })
          //TODO set expiry ?
        } else {
          // Check if refresh is required.
          const metadataRef = firebase
            .database()
            .ref('metadata/' + user.uid + '/refreshTime')

          metadataRef.on('value', async data => {
            if (!data.exists) {
              return
            }
            // Force refresh to pick up the latest custom claims changes.
            // Note this is always triggered on first call. Further optimization could be
            // added to avoid the initial trigger when the token is issued and already contains
            // the latest claims.
            const token = await user.getIdToken(true)
            //TODO set expiry ?
            setAuthState({ status: "in", token: token });
          })
        }
      } else {
        setAuthState({ status: "out", token: "" });
      }
    })
    return () => {
      unsubscribe();
    }
  }, [])

  return <AuthenticationContext.Provider value={authState} {...props} />;

}
const useAuth = () => React.useContext(AuthenticationContext)

export { AuthenticationProvider, useAuth }

