import React from "react";
import {
  Switch,
  Route,
  useLocation,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import { RiEyeLine } from "react-icons/ri";
import toaster from "toasted-notes";
import CornerButton from "./CornerButton";
import SideTab from "./SideTab";
import PageViewContainer from "../../../components/structure/PageViewContainer";
import Button from "../../../components/Button";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Formik } from "formik";
import LauncherPreview from "../Previews/LauncherPreview";
import { useQuery, useMutation, useQueryCache } from "react-query";
import { useGraphQl } from "../../../GraphqlClient";
import { useWorkspace } from "../../../WorkspaceContext";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../api/widgets";

const Launcher = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const pageRoute = location.pathname.split("/")[3];
  const graphQL = useGraphQl();
  const queryCache = useQueryCache();
  const workspace = useWorkspace();
  const { data, refetch } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );
  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
    refetch();
  };
  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryWidgetSettings");
      toaster.notify("Launcher settings saved successfully", {
        duration: 2000,
      });
    },
  });

  const getTitle = () => {
    switch (pageRoute) {
      case "corner-button":
        return "Corner Button";
      case "side-tab":
        return "Side Tab";
      default:
        return "Look & Feel";
    }
  };

  const initialValues = {
    id: data && data.widgets_cornerwidget[0].id,
    cornerButton: {
      isActive:
        data &&
        data.widgets_cornerwidget[0].launcher_config.cornerButton.isActive,
      verticalPositioning:
        data &&
        data.widgets_cornerwidget[0].launcher_config.cornerButton
          .verticalPositioning,
      visibility: {
        desktop:
          data &&
          data.widgets_cornerwidget[0].launcher_config.cornerButton.visibility
            .desktop,
        mobile:
          data &&
          data.widgets_cornerwidget[0].launcher_config.cornerButton.visibility
            .mobile,
        showOnProductPage:
          data &&
          data.widgets_cornerwidget[0].launcher_config.cornerButton.visibility
            .showOnProductPage,
        showOnCollectionPage:
          data &&
          data.widgets_cornerwidget[0].launcher_config.cornerButton.visibility
            .showOnCollectionPage,
      },
      size: {
        desktop:
          data &&
          data.widgets_cornerwidget[0].launcher_config.cornerButton.size
            .desktop,
        mobile:
          data &&
          data.widgets_cornerwidget[0].launcher_config.cornerButton.size.mobile,
      },
      shape:
        data && data.widgets_cornerwidget[0].launcher_config.cornerButton.shape,
    },
    sideTab: {
      isActive:
        data && data.widgets_cornerwidget[0].launcher_config.sideTab.isActive,
      visibility: {
        desktop:
          data &&
          data.widgets_cornerwidget[0].launcher_config.sideTab.visibility
            .desktop,
        mobile:
          data &&
          data.widgets_cornerwidget[0].launcher_config.sideTab.visibility
            .mobile,
            showOnProductPage:
              data &&
              data.widgets_cornerwidget[0].launcher_config.sideTab.visibility
                .showOnProductPage,
            showOnCollectionPage:
              data &&
              data.widgets_cornerwidget[0].launcher_config.sideTab.visibility
                .showOnCollectionPage,
      },
      onClick:
        data && data.widgets_cornerwidget[0].launcher_config.sideTab.onClick,
      verticalPositioning:
        data &&
        data.widgets_cornerwidget[0].launcher_config.sideTab
          .verticalPositioning,
      text: data && data.widgets_cornerwidget[0].launcher_config.sideTab.text,
    },
  };
  if (data)
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          const launcherConfig = {
            launcher_config: {
              cornerButton: {
                isActive: values.cornerButton.isActive,
                verticalPositioning: values.cornerButton.verticalPositioning,
                visibility: {
                  desktop: values.cornerButton.visibility.desktop,
                  mobile: values.cornerButton.visibility.mobile,
                  showOnProductPage: values.cornerButton.visibility.showOnProductPage,
                  showOnCollectionPage: values.cornerButton.visibility.showOnCollectionPage,
                },
                size: {
                  desktop: values.cornerButton.size.desktop,
                  mobile: values.cornerButton.size.mobile,
                },
                shape: values.cornerButton.shape,
              },
              sideTab: {
                isActive: values.sideTab.isActive,
                visibility: {
                  desktop: values.sideTab.visibility.desktop,
                  mobile: values.sideTab.visibility.mobile,
                  showOnProductPage: values.sideTab.visibility.showOnProductPage,
                  showOnCollectionPage: values.sideTab.visibility.showOnCollectionPage,
                },
                onClick: values.sideTab.onClick,
                verticalPositioning: values.sideTab.verticalPositioning,
                text: values.sideTab.text,
              },
            },
          };

          updateWidgetMutation({
            id: { id: values.id },
            config: launcherConfig,
          });
        }}
      >
        {(props) => (
          <PageViewContainer
            title={getTitle()}
            subTitle="Launcher"
            action={
              <div className="flex">
                {props.dirty ? (
                  <Button
                    type="primary"
                    icon={<RiEyeLine />}
                    onClick={() => props.submitForm()}
                  >
                    Save Settings
                  </Button>
                ) : (
                  <Button
                    className="mr-2"
                    type="ghost"
                    onClick={() => {
                      if (workspace.data)
                        window.open(`https://${workspace.data.storeUrl}`);
                    }}
                    icon={<RiEyeLine />}
                  >
                    View it on my store
                  </Button>
                )}
              </div>
            }
          >
            <div className="flex w-full h-full ">
              <div className="desktop:max-w-xl mobile:w-full h-full flex-shrink-0 flex-grow px-8 overflow-y-auto">
                <Switch>
                  <Route
                    path={`${path}/corner-button/`}
                    render={() => <CornerButton {...props} />}
                  />
                  <Route
                    path={`${path}/side-tab/`}
                    render={() => <SideTab {...props} />}
                  />

                  <Route path={`${path}`}>
                    <Redirect to="/widgets/launcher/corner-button/" />
                  </Route>
                </Switch>
              </div>
              <div className="flex-grow h-full bg-gray-100 border-l mobile:hidden desktop:flex items-center justify-center overflow-hidden">
                <LauncherPreview
                  cornerButtonConfig={props.values.cornerButton}
                  sideTabConfig={props.values.sideTab}
                  alignment={
                    data.widgets_cornerwidget[0].common_config.alignment
                  }
                  themeColor={
                    data.widgets_cornerwidget[0].common_config.bgEndColor
                  }
                />
              </div>
            </div>
          </PageViewContainer>
        )}
      </Formik>
    );
  else return <LoadingSpinner />;
};

export default Launcher;
