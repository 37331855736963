import React,{useState} from "react";
import TagGroup from "@atlaskit/tag-group";
import Tag from "@atlaskit/tag";
import Textfield from "@atlaskit/textfield";
import Button from "./Button";

const List = (props) => {
  const [inputValue, setInputValue] = useState();
  const handleAddition = () => {
    try {
      const url = new URL(inputValue);
      props.onAddition(url.pathname);
    } catch (err) {
      window.alert("Invalid URL");
    }
  };
  return (
    <>
      <TagGroup>
        {props.arrayItems.map((url, index) => (
          <Tag
            onAfterRemoveAction={() => props.onDeletion(index)}
            key={url}
            text={url}
          />
        ))}
      </TagGroup>
      <div className="flex items-center">
        <Textfield
          defaultValue={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          name="basic"
        />
        <div className="flex-none ml-2">
          <Button onClick={() => handleAddition()} type="ghost">
            Add Url
          </Button>
        </div>
      </div>
    </>
  );
};
export default List;
