import React from 'react'
import AuthorisedApp from './AuthorisedApp';
import UnauthorisedApp from './UnauthorisedApp';
import {useAuth} from './AuthenticationContext';
import {useWorkspace} from './WorkspaceContext';

function App () {
  const authState = useAuth();
  const workspace = useWorkspace();
  
  return authState.status === "in" && workspace.data ? (
    <AuthorisedApp />
  ) : (
    <UnauthorisedApp />
  );

}

export default App
