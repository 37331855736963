import React, { useState } from "react";
import Button from "./Button";
import PricingModal from "./PricingModal";
import { FaChessQueen } from "react-icons/fa";
{/* type Props = { */}
{/*   title: string; */}
{/*   description?: React.ReactNode; */}
{/*   children: React.ReactNode; */}
{/*   disabled?: boolean; */}
{/*   paywall?: boolean; */}
{/*   paywallPlan?: number; */}
{/* }; */}

const CustomiserUnit = ({
  title,
  description,
  children,
  disabled,
  paywall,
  paywallPlan,
}) => {
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  return (
    <div
      className={`py-5 ${
        disabled && "opacity-25 pointer-events-none cursor-not-allowed"
      }  max-w-4xl`}
    >
      <div className={`${paywall && "opacity-25"}`}>
        <p className="font-bold">{title}</p>
        {description && (
          <small className=" text-gray-700 mt-1">{description}</small>
        )}
      </div>
      {paywall ? (
        <div className="px-4 py-2 border border-primary-500 my-3 rounded-md  flex items-center">
          <div className=" mr-3 text-center text-primary-600 p-2 border border-primary-300 rounded-lg">
            <div className="flex items-center leading-none">
              <span className="text-lg">$</span>
              <span className="text-3xl m-0 font-extrabold">
                {paywallPlan === 3 ? "12" : "5"}
              </span>
            </div>
            <div className="text-xs m-0">/month</div>
          </div>
          <div className="flex-grow">
            <p className="font-bold text-sm">
              Subscribe to {paywallPlan === 5 ? "Lite" : "PRO"} plan to
              unlock this feature
            </p>
            {/* <small className="text-gray-600">
              This feature is not available in your current plan
            </small> */}
          </div>
          <div>
            <Button type="link" onClick={() => setIsBillingModalOpen(true)}>
              Upgrade
            </Button>
          </div>
        </div>
      ) : (
        <div className="mt-3">
          {disabled ? (
            <div className="bg-gray-200 w-64 h-12 rounded-md " />
          ) : (
            <div>{children}</div>
          )}
        </div>
      )}
      <PricingModal
        hightlightPlan={paywallPlan}
        title={`You need a ${
          paywallPlan === 5 ? "Lite" : "PRO"
        } plan to access this feature`}
        isOpen={isBillingModalOpen}
        onClose={() => setIsBillingModalOpen(false)}
      />
    </div>
  );
};

export default CustomiserUnit;
