import React from "react";
import CategoryNavButtons from "./CategoryNavButtons";


const DesktopCategoryNav = () => {
  return (
    <div className="row-start-2 row-end-3 desktop:flex flex-col items-start p-3 mt-6  mobile:hidden z-10">
      <CategoryNavButtons />
    </div>
  );
};

export default DesktopCategoryNav;
