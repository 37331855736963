import React from 'react'
import {AuthenticationProvider} from './AuthenticationContext'
import {WorkspaceProvider} from './WorkspaceContext'
import {GraphqlClientProvider} from './GraphqlClient'
import { BrowserRouter as Router} from "react-router-dom";


function AppContextProviders(props) {
  return(
      <AuthenticationProvider>
        <Router>
          <GraphqlClientProvider>
            <WorkspaceProvider>
              {props.children}
            </WorkspaceProvider>
          </GraphqlClientProvider>
        </Router>
      </AuthenticationProvider>
  )

}

export default AppContextProviders
