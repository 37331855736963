import React, { useState, useEffect } from "react";
import { RiLoader2Line } from "react-icons/ri";

function UnauthorisedApp() {
 const [messageVisibility, setMessageVisibility] = useState(false);

 //During authentication if there is already a session persisting it is asked to logout and this timeout prevents the signout message comming up during that process
 useEffect(() => {
  const timeoutId = setTimeout(() => {
     setMessageVisibility(true);
   }, 12000);
   return function cleanup() {
    clearTimeout(timeoutId);
  }
 }, []);

  return (
     
   <div className="w-screen h-screen flex items-center justify-center">
     {messageVisibility ? (
       <div className="text-center">
         <div className="text-5xl">⌛️</div>
         <h2 className="text-primary-500">Session expired</h2>
         <p>Your session has expired. Please login again to continue working.</p>
       </div>
     ): 
     <div className=" animate-spin text-5xl text-primary-500">
       <RiLoader2Line />
     </div>
     }
   </div>
 );
}

export default UnauthorisedApp;

