import React from "react";

{/* interface Props { */}
{/*   pageView: React.ReactNode; */}
{/*   navigationBar: React.ReactNode; */}
{/* } */}
const Section = ({ pageView, navigationBar }) => {
  return (
    <div className="section-background flex row-start-2 row-end-3 desktop:col-start-2  desktop:shadow-2xl desktop:rounded-tl-section overflow-hidden desktop:border-t desktop:border-l mobile:col-start-1 mobile:col-end-3 z-20">
      <div className="w-64 desktop:flex flex-col flex-none bg-white bg-opacity-50 border-r mobile:hidden">
        {navigationBar}
      </div>
      {pageView}
    </div>
  );
};

export default Section;
