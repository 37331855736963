/* --------------- Queries ------------------- */
export const queryWidgetSettings = `
  query queryWidgetSettings{
    workspaces {
      source {
        default_language
      }
    }
    widgets_cornerwidget {
      id
      common_config
      launcher_config
      faq_config
      corner_lang
      faq_lang
    }
      accounts{
    id
  }
  }
`;

/* --------------- Mutations ------------------- */
export const updateWidgetSettings = `
  mutation updateCornSettings ($widgetSettingsId:widgets_cornerwidget_pk_columns_input!, $widgetSettingsInput:widgets_cornerwidget_set_input){
    update_widgets_cornerwidget_by_pk(pk_columns: $widgetSettingsId, _set:$widgetSettingsInput) {
      id
    }
  }
`;
