import React from "react";
import CustomiserUnit from "../../../components/CustomiserUnit";
import ArrayInput from "../../../components/ArrayInput";
import Textfield from "@atlaskit/textfield";
import { FieldArray } from "formik";

const Preferences = (props) => {
  
  return (
    <>
      <CustomiserUnit
        title="Blocked URLs"
        description="The widgets will not be rendered in these URLs"
      >
        <FieldArray
          name="blockedUrls"
          render={(arrayHelpers) => (
            <ArrayInput
              onAddition={(value) => arrayHelpers.push(value)}
              onDeletion={(index) => arrayHelpers.remove(index)}
              arrayItems={props.values.blockedUrls}
            />
          )}
        />
      </CustomiserUnit>
      <CustomiserUnit
        title="Z-index Value"
        description="Controls whether the Cornercart widget is displayed OVER an existing element on your website, or BEHIND it."
      >
        <div className="max-w-xs">
          <Textfield
            defaultValue={props.values.zIndex}
            onChange={(e) => {
              props.setFieldValue("zIndex", e.target.value);
            
            }}
            name="basic"
            type="number"
          />
        </div>
      </CustomiserUnit>
    </>
  );
};

export default Preferences;
