import React, { useState, useRef, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
// here we are using their default css and also we are overiding some of the css with our own custom css
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./cutomisations.css";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Checkbox } from "@atlaskit/checkbox";
import { RiCheckboxCircleFill, RiArrowDownSLine } from "react-icons/ri";
import { FaChessQueen } from "react-icons/fa";
import Button from "../Button";
import Dropdown from "../Dropdown";
import { Controlled } from "react-codemirror2";
import PricingModal from "../PricingModal";
import PaywallBadge from "../PaywallBadge";
import { useWorkspace } from "../../WorkspaceContext";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/xq-light.css";
import "codemirror/theme/material.css";
import { printIntrospectionSchema } from "graphql";
require("codemirror/theme/neat.css");
require("codemirror/mode/javascript/javascript");
require("codemirror/mode/gfm/gfm");
require("codemirror/mode/htmlmixed/htmlmixed");
require("codemirror/mode/xml/xml");
require("codemirror/lib/codemirror");

//showdown is used to convert between html to md and back
const showdown = require("showdown");
const converter = new showdown.Converter();

// This is a custom interval hook wich ecxecutes a specific function on a particular delay
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const TextEditor = ({ content, onChange }) => {
  const workspace = useWorkspace();
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const htmlToEditorState = (input) => {
    let contentBlock = htmlToDraft(input);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
  };

  const initialiseHtml = content;
  const initialiseEditorState = htmlToEditorState(
    initialiseHtml ? initialiseHtml : ""
  );
  const initialiseMd = converter.makeMarkdown(initialiseHtml);

  const [editorMode, setEditorMode] = useState("rich");
  const [editorState, setEditorState] = useState(initialiseEditorState);
  const [stripStyles, setStripStyles] = useState(false);
  const [htmlState, setHtmlState] = useState(initialiseHtml);
  const [mdState, setMdState] = useState(initialiseMd);

  // Here we are running a small counter which triggers the onChange function when it reaches the 0
  const [onChangeTriggerCounter, setOnChangeTriggerCounter] = useState(1);
  const [valueChanged, setValueChanged] = useState(false);
  useInterval(() => {
    if (onChangeTriggerCounter === 0) {
      // this condition makes sure that the onchange is not triggered is ther is no change in the value
      if (valueChanged) {
        switch (editorMode) {
          case "rich": {
            let htmlValue = draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            );
            onChange(htmlValue);
            break;
          }
          case "markdown": {
            let htmlValue = converter.makeHtml(mdState);
            onChange(htmlValue);
            break;
          }
          case "html": {
            onChange(htmlState);
            break;
          }
        }
        setValueChanged(false);
      }
    } else setOnChangeTriggerCounter(onChangeTriggerCounter - 1);
  }, 300);

  const handleEditorModeChange = (toMode) => {
    let fromMode = editorMode;
    if (fromMode === "rich" && toMode === "html") {
      let htmlValue = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      setHtmlState(htmlValue);
    } else if (fromMode === "rich" && toMode === "markdown") {
      let htmlValue = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      let mdValue = converter.makeMarkdown(htmlValue);
      setMdState(mdValue);
    } else if (fromMode === "html" && toMode === "rich") {
      let tempEditorState = htmlToEditorState(htmlState);
      setEditorState(tempEditorState);
    } else if (fromMode === "html" && toMode === "markdown") {
      let mdValue = converter.makeMarkdown(htmlState);
      setMdState(mdValue);
    } else if (fromMode === "markdown" && toMode === "html") {
      let htmlValue = converter.makeHtml(mdState);
      setHtmlState(htmlValue);
    } else if (fromMode === "markdown" && toMode === "rich") {
      let htmlValue = converter.makeHtml(mdState);
      let tempEditorState = htmlToEditorState(htmlValue);
      setEditorState(tempEditorState);
    }
    setEditorMode(toMode);
  };

  const richEditorOptions = () => {
    if (
      workspace.data?.feature_flag.apps?.supportcorner
        ?.articles_allow_images_video
    )
      return [
        "blockType",
        "inline",
        "list",
        "link",
        "textAlign",
        "colorPicker",
        "image",
        "embedded",
      ];
    else
      return [
        "blockType",
        "inline",
        "list",
        "link",
        "textAlign",
        "colorPicker",
      ];
  };

  const renderEditor = () => {
    switch (editorMode) {
      case "rich": {
        return (
          <div className="max-h-full">
            <Editor
              editorState={editorState}
              toolbarClassName="custom-toolbar"
              placeholder="Write article here..."
              stripPastedStyles={stripStyles}
              toolbar={{
                options: richEditorOptions(),
                inline: {
                  options: [
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    "monospace",
                  ],
                  textAlign: {
                    inDropdown: true,
                    options: ["left", "center", "right", "justify"],
                  },
                  embedded: {
                    defaultSize: {
                      height: "auto",
                      width: "auto",
                    },
                  },
                  link: {
                    inDropdown: false,
                    defaultTargetOption: "_self",
                    options: ["link", "unlink"],
                  },
                  colorPicker: {
                    colors: [
                      "rgb(97,189,109)",
                      "rgb(26,188,156)",
                      "rgb(84,172,210)",
                      "rgb(44,130,201)",
                      "rgb(147,101,184)",
                      "rgb(71,85,119)",
                      "rgb(204,204,204)",
                      "rgb(65,168,95)",
                      "rgb(0,168,133)",
                      "rgb(61,142,185)",
                      "rgb(41,105,176)",
                      "rgb(85,57,130)",
                      "rgb(40,50,78)",
                      "rgb(0,0,0)",
                      "rgb(247,218,100)",
                      "rgb(251,160,38)",
                      "rgb(235,107,86)",
                      "rgb(226,80,65)",
                      "rgb(163,143,132)",
                      "rgb(239,239,239)",
                      "rgb(255,255,255)",
                      "rgb(250,197,28)",
                      "rgb(243,121,52)",
                      "rgb(209,72,65)",
                      "rgb(184,49,47)",
                      "rgb(124,112,107)",
                      "rgb(209,213,216)",
                    ],
                  },
                },
                blockType: {
                  className: "block-type",
                },
              }}
              onEditorStateChange={(input) => {
                setEditorState(input);
                setValueChanged(true);
                setOnChangeTriggerCounter(1);
              }}
            />
          </div>
        );
        break;
      }
      case "html": {
        return (
          <Controlled
            value={htmlState ? htmlState : ""}
            options={{
              mode: "htmlmixed",
              theme: "material",
              lineNumbers: true,
            }}
            onBeforeChange={(editor, data, value) => {
              setHtmlState(value);
              setValueChanged(true);
              setOnChangeTriggerCounter(1);
            }}
            className=" cursor-text border-gray-400 w-full flex-grow bg-gray-100 rounded-lg overflow-hidden"
          />
        );
        break;
      }
      case "markdown": {
        return (
          <Controlled
            value={mdState ? mdState : ""}
            options={{
              mode: "gfm",
              highlightFormatting: true,
              theme: "xq-light",
            }}
            onBeforeChange={(editor, data, value) => {
              setMdState(value);
              setValueChanged(true);
              setOnChangeTriggerCounter(1);
            }}
            className=" cursor-text border-gray-400 w-full flex-grow bg-gray-100 rounded-lg overflow-hidden"
          />
        );
        break;
      }
    }
  };

  return (
    <>
      <div className="flex justify-end items-center mt-4">
        {!workspace.data?.feature_flag.apps?.supportcorner
          ?.articles_allow_images_video && (
          <a
            role="presentation"
            onClick={() => setIsBillingModalOpen(true)}
            className="flex items-center px-3 text-primary-500 border border-primary-500 rounded-full"
          >
            <FaChessQueen />
            <span className="ml-2">
              Upgrade to Standard plan to get image upload and video embed
              options
            </span>
          </a>
        )}
        {editorMode === "rich" && (
          <div className="flex items-center py-1 px-2 border rounded-lg">
            <Checkbox
              onChange={(e) => {
                setStripStyles(e.target.checked);
              }}
              label=""
              name="checkbox-basic"
              defaultChecked={stripStyles}
            />
            <small>Paste text without styles</small>
          </div>
        )}
        <Dropdown
          alignment="right"
          type="link"
          icon={<RiArrowDownSLine />}
          triggerText="Editor Mode"
        >
          <Button
            onClick={() => {
              handleEditorModeChange("rich");
            }}
            type="link"
            className="mr-2"
            icon={editorMode === "rich" && <RiCheckboxCircleFill />}
          >
            Rich text editor
          </Button>
          <Button
            onClick={() => {
              if (
                workspace.data?.feature_flag.apps?.supportcorner
                  ?.articles_html_editor
              )
                handleEditorModeChange("html");
              else setIsBillingModalOpen(true);
            }}
            type="link"
            className="mr-2"
            icon={editorMode === "html" && <RiCheckboxCircleFill />}
          >
            <div className="flex items-center">
              HTML editor{" "}
              {!workspace.data?.feature_flag.apps?.supportcorner
                ?.articles_html_editor && <PaywallBadge />}
            </div>
          </Button>
          <Button
            onClick={() => {
              if (
                workspace.data?.feature_flag.apps?.supportcorner
                  ?.articles_markdown_editor
              )
                handleEditorModeChange("markdown");
              else setIsBillingModalOpen(true);
            }}
            type="link"
            className="mr-2 flex items-center"
            icon={editorMode === "markdown" && <RiCheckboxCircleFill />}
          >
            <div className="flex items-center">
              Markdown editor{" "}
              {!workspace.data?.feature_flag.apps?.supportcorner
                ?.articles_markdown_editor && <PaywallBadge />}
            </div>
          </Button>
        </Dropdown>
      </div>
      {renderEditor()}
      <PricingModal
        title="You need a higher plan to access this feature"
        isOpen={isBillingModalOpen}
        onClose={() => setIsBillingModalOpen(false)}
      />
    </>
  );
};

export default TextEditor;
