import React from 'react'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from 'react-router-dom'

import ArticlesPage from './faq/ArticlesPage';
import ArticleEdit from "./faq/ArticleEdit";
import CategoryPage from "./faq/CategoryPage";
import CategoryEdit from "./faq/CategoryEdit";
import FaqPage from "./faq/FaqPage";

export default function AssistantRouter(){ 
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/faq/articles/`} component={ArticlesPage} />
      <Route
        exact
        path={`${path}/faq/articles/:id/edit`}
        component={ArticleEdit}
      />
      <Route exact path={`${path}/faq/categories/`} component={CategoryPage} />
      <Route
        exact
        path={`${path}/faq/categories/:id`}
        component={CategoryEdit}
      />
      <Route
        exact
        path={`${path}/faq/page`}
        component={FaqPage}
      />
      <Route path={`${path}/faq/`}>
        <Redirect to={`/assistant/faq/articles/`} />
      </Route>
      <Route exact path={path}>
        <Redirect to={`/assistant/faq/articles/`} />
      </Route>
    </Switch>
  );
}
