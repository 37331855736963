import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryCache } from "react-query";
import Select from "@atlaskit/select";
import CustomiserUnit from "../../../../components/CustomiserUnit";
import Button from "../../../../components/Button";
import Dropdown from "../../../../components/Dropdown";
import { useGraphQl } from "../../../../GraphqlClient";
import { useWorkspace } from "../../../../WorkspaceContext";
import { RiCloseFill, RiAddLine } from "react-icons/ri";
import { updateSourceDetails } from "../../../../api/workspace";
import {
  queryWidgetSettings,
  updateWidgetSettings,
} from "../../../../api/widgets";
import toaster from "toasted-notes";

import {langOptions} from "../../../../variables"

export default function General(props) {
  const graphQL = useGraphQl();
  const workspace= useWorkspace();
  const queryCache = useQueryCache();

  const [currentLanguages, setCurrentLanguages] = useState([]);

  const { data } = useQuery("queryWidgetSettings", () =>
    graphQL(queryWidgetSettings)
  );

  const updateDefaultLanguage = async (input) => {
    await graphQL(updateSourceDetails, {
      sourceId: { id: workspace.data.sourceid },
      sourceInput: {
        default_language: input,
      },
    });
  };

  const updateWidget = async (values) => {
    await graphQL(updateWidgetSettings, {
      widgetSettingsId: values.id,
      widgetSettingsInput: values.config,
    });
  };

  const [updateDefaultLanguageMutation] = useMutation(updateDefaultLanguage, {
    onSuccess: () => {
      queryCache.invalidateQueries("queryWidgetSettings");
      toaster.notify("Default language updated", {
        duration: 2000,
      });
    },
  });

  const [updateWidgetMutation] = useMutation(updateWidget, {
    onSuccess: () => {
      toaster.notify("Language Translations Updated", {
        duration: 2000,
      });
      queryCache.invalidateQueries("queryWidgetSettings");
      initValues()
    },
  });

  const handleLanguageDeletion = (lang) => {
    if(lang !== data.workspaces[0].source.default_language)
    {
    let tempFaqLang = data.widgets_cornerwidget[0].faq_lang;
    let tempCornerLang = data.widgets_cornerwidget[0].corner_lang;
    delete tempCornerLang[lang];
    delete tempFaqLang[lang];
    updateWidgetMutation({
      id: { id: data && data.widgets_cornerwidget[0].id },
      config: {
        faq_lang: tempFaqLang,
        corner_lang: tempCornerLang,
      },
    });}
    else toaster.notify("Cannot delete this translation since it is your default language", {
      duration: 5000,
    });
  };

  const handleLanguageAddition = async (langCode) => {
    let tempCornerLang = data.widgets_cornerwidget[0].corner_lang;
    let tempFaqLang = data.widgets_cornerwidget[0].faq_lang;

    let cornerLangToBeAdded = await require(`../../../../i18n/default-values/corner-widget/${langCode}.json`);
    let faqLangToBeAdded = await require(`../../../../i18n/default-values/faq-page/${langCode}.json`);

    // this syntax of [`${...}`] is used for defining dynamic keys with object literals
    let newFaqLang = { ...tempFaqLang, [`${langCode}`]: faqLangToBeAdded };
    let newCornerLang = {
      ...tempCornerLang,
      [`${langCode}`]: cornerLangToBeAdded,
    };

    updateWidgetMutation({
      id: { id: data && data.widgets_cornerwidget[0].id },
      config: {
        corner_lang: newCornerLang,
        faq_lang: newFaqLang,
      },
    });
  };

  const initValues = () => {
    let langConfig = data.widgets_cornerwidget[0].faq_lang;
      let langArray = Object.keys(langConfig);
      setCurrentLanguages(langArray);
  }

  useEffect(() => {
    if (data) {
      initValues()
    }
  }, [data]);

  return (
    <div className="px-8 py-4">
      <CustomiserUnit
        title="Active languages"
        description="The UI of the widget and faq page will be translated to the following languages"
      >
        <div className="flex">
          {currentLanguages.map((item) => {
            let displayItem= langOptions.find(
              (lang) => lang.value === item
            );
            return (
              <div className={`flex items-center border rounded-lg mr-2 pl-2 font-semibold bg-gray-100`}>
                {" "}
                {displayItem.label}{" "}
                <Button
                  type="danger-link"
                  onClick={() => handleLanguageDeletion(displayItem.value)}
                >
                  <RiCloseFill />
                </Button>
              </div>
            );
          })}

          <Dropdown alignment="right" type="outline" icon={<RiAddLine />} triggerText="Add Language">
            <div className="flex flex-wrap max-w-xs">
              {langOptions.map((item) => {
                if (!currentLanguages.includes(item.value))
                  return (
                    <Button
                      onClick={() => handleLanguageAddition(item.value)}
                      type="ghost"
                      className="m-1"
                    >
                      {item.label}
                    </Button>
                  );
              })}
            </div>
          </Dropdown>
        </div>
      </CustomiserUnit>
      
      <CustomiserUnit
        title="Select your default language"
        description="This language will be the default language the app takes if the user selected language is not setup"
      >
          {data && (
            <Select
              value={langOptions.find(
                (option) =>
                  option.value === data.workspaces[0].source.default_language
              )}
              onChange={(e) => {
                updateDefaultLanguageMutation(e.value);
              }}
              className="w-48 mr-2"
              classNamePrefix="react-select"
              options={langOptions.filter((item) =>
                currentLanguages.includes(item.value)
              )}
            />
          )}
      </CustomiserUnit>
    </div>
  );
}
