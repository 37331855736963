import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import Select from "@atlaskit/select";
import Tooltip from "@atlaskit/tooltip";
import toaster from "toasted-notes";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { Link } from "react-router-dom";
import {
  RiArrowUpDownLine,
  RiShareForwardBoxLine,
  RiCheckboxBlankCircleFill,
  RiDeleteBin2Line,
  RiArrowDownSLine,
  RiFileTextLine,
  RiFileLockLine,
  RiQuestionLine,
  RiStarLine,
  RiStarFill,
} from "react-icons/ri";
import Button from "./Button";
import Dropdown from "./Dropdown";
import List from "./List";
import { useGraphQl } from "../GraphqlClient";
import {
  bulkUpdateAssistantArticle,
  bulkDeleteAssistantArticle,
  updateAssistantArticle,
} from "../api/assistant";

export default function ArticleList({
  data,
  onUpdate,
  emptyState,
  draggable,
}) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [articleList, setArticleList] = useState([]);
  const [activeSortOption, setActiveSortOption] = useState("all");
  const graphQL = useGraphQl();
  const [isArticleDeleteWarningOpen, setIsArticleDeleteWarningOpen] = useState(
    false
  );

  const updateArticle = async (input) => {
    await graphQL(updateAssistantArticle, {
      articleId: { id: input.id },
      articleInput: input.values,
    });
  };

  const bulkDeleteArticle = async (input) => {
    await graphQL(bulkDeleteAssistantArticle, {
      idArray: input.id,
    });
  };

  const bulkUpdateArticles = async (input) => {
    let valueToUpdate;
    switch (input.updateType) {
      case "addToCategory":
        valueToUpdate = { categoryid: data.assistant_categories[0].id };
        break;
      case "uncategorize":
        valueToUpdate = { categoryid: null };
        break;
      case "publish":
        valueToUpdate = { is_published: true };
        break;
      case "draft":
        valueToUpdate = { is_published: false };
        break;
      case "feature":
        valueToUpdate = { is_featured: true };
        break;
      case "unfeature":
        valueToUpdate = { is_featured: false };
        break;
    }
    await graphQL(bulkUpdateAssistantArticle, {
      idArray: input.id,
      newCategory: valueToUpdate,
    });
  };

  const [updateArticleMutation] = useMutation(updateArticle, {
    onSuccess: () => {
      onUpdate();
    },
  });

  const [bulkUpdateArticleMutation] = useMutation(bulkUpdateArticles, {
    onSuccess: () => {
      toaster.notify("Updated successfully", {
        duration: 2000,
      });
      onUpdate();
    },
  });

  const [bulkDeleteArticleMutation] = useMutation(bulkDeleteArticle, {
    onSuccess: () => {
      toaster.notify("Deletion Complete", {
        duration: 2000,
      });
      onUpdate();
    },
  });
  const handleArticledeletion = () => {
    bulkDeleteArticleMutation({
      id: selectedItems,
    });
  };
  const handleBulkAction = (action, idArray) => {
    bulkUpdateArticleMutation({
      id: idArray,
      updateType: action,
    });
  };

  const getSortedData = () => {
    switch (activeSortOption) {
      case "all":
        return articleList;
        break;
      case "published":
        return articleList.filter(
          (article) => article.is_published === true
        );
        break;
      case "draft":
        return articleList.filter(
          (article) => article.is_published === false
        );
        break;
      case "featured":
        return articleList.filter(
          (article) => article.is_featured === true
        );
        break;
      case "nonfeatured":
        return articleList.filter(
          (article) => article.is_featured === false
        );
        break;
    }
  };
  const sortOptions = [
    { label: "All", value: "all" },
    { label: "Published Articles", value: "published" },
    { label: "Drafts", value: "draft" },
    { label: "Featured", value: "featured" },
    { label: "Non Featured", value: "nonfeatured" },
  ];

  const getCategoryName = (categoryId) => {
    const category = data.assistant_categories.find(
      (category) => category.id === categoryId
    );
    if(category)
    return (
      <>
        <img width="16px" className="mr-2" src={category.icon_url} alt="" />{" "}
        {category.name}
      </>
    );
  };

  // When user drags an article, the whole new articleList array which is aranged in the new order is passed on to this fuction. Here we update each artcle's position_rank with its array index
  const handleDrag = (updatedList) => {
    updatedList.map((item, index) => {
      updateArticleMutation({
        id: item.id,
        values: {
          position_rank: index,
        },
      });
    });
  };

  // each time anything is updated this functio makes sure the updated article list is sorted.
  const initialiseList = () => {
    const arrangedArticleList = data.assistant_articles.sort(
      (a, b) => a.position_rank - b.position_rank
    );
    setArticleList(arrangedArticleList);
  };

  useEffect(() => {
    initialiseList();
  }, [data]);

  return (
    <>
      <div className="flex py-2 px-4 flex-wrap">
        <div className="flex items-center mt-3">
          <p className="mr-3 desktop:block mobile:hidden">View</p>
          <Select
            value={sortOptions.find(
              (option) => option.value === activeSortOption
            )}
            onChange={(e) => setActiveSortOption(e.value)}
            className="w-48 mr-2"
            options={sortOptions}
          />
        </div>
        <div className="flex items-center mt-3">
          <Dropdown
            alignment="right"
            type="link"
            icon={<RiArrowDownSLine />}
            triggerText="Bulk Actions"
            disabled={selectedItems.length === 0}
          >
            <Button
              onClick={() => {
                handleBulkAction("uncategorize", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiShareForwardBoxLine />}
            >
              Remove from this category
            </Button>
            <Button
              onClick={() => {
                handleBulkAction("publish", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiFileTextLine />}
            >
              Change status to published
            </Button>
            <Button
              onClick={() => {
                handleBulkAction("draft", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiFileLockLine />}
            >
              Change status to draft
            </Button>
            <Button
              onClick={() => {
                handleBulkAction("feature", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiStarFill />}
            >
              Feature articles
            </Button>
            <Button
              onClick={() => {
                handleBulkAction("unfeature", selectedItems);
              }}
              type="link"
              className="mr-2"
              icon={<RiStarLine />}
            >
              Unfeature articles
            </Button>
            <Button
              onClick={() => {
                setIsArticleDeleteWarningOpen(true);
              }}
              type="danger-link"
              className="mr-2"
              icon={<RiDeleteBin2Line />}
            >
              Delete selected items
            </Button>
          </Dropdown>
        </div>
       <div className="mt-3">
       {activeSortOption !== "all" && (
            <Button
              onClick={() => {
                setActiveSortOption("all");
              }}
              type="link"
              className="mr-2"
              icon={<RiArrowUpDownLine />}
            >
              Reorder Articles
            </Button>
          )}
       </div>
        <ModalTransition>
          {isArticleDeleteWarningOpen && (
            <Modal
              actions={[
                {
                  text: "Delete it",
                  onClick: () => {
                    handleArticledeletion();
                    setIsArticleDeleteWarningOpen(false);
                  },
                },
                {
                  text: "No, keep it",
                  onClick: () => {
                    setIsArticleDeleteWarningOpen(false);
                  },
                },
              ]}
              onClose={() => {
                setIsArticleDeleteWarningOpen(false);
              }}
              heading="You’re about to delete these selected articles"
              appearance="danger"
            >
              <p>
                We won't be able to undo this later, are you sure you want to
                conitnue with the deletion?
              </p>
            </Modal>
          )}
        </ModalTransition>
      </div>
      <div className="desktop:px-8 mobile:px-3  pt-4 ">
        <List
          draggable={draggable && activeSortOption === "all"}
          onDrag={handleDrag}
          items={getSortedData()}
          selectedItemsId={selectedItems}
          onSelectionChange={setSelectedItems}
          emptyState={emptyState}
          header={
            <div className="grid grid-cols-12 items-center justify-center ">
              <div className="desktop:col-span-6 mobile:col-span-12 px-7">
                <p className="font-bold text-gray-600">Title</p>
              </div>
              <div className="col-span-3 desktop:block mobile:hidden">
                <p className="font-bold text-gray-600">Status</p>
              </div>
              <div className="col-span-3 desktop:block mobile:hidden">
                <p className="font-bold text-gray-600">
                  <Tooltip content="Choose whether to show article in the widget's featured section">
                    <p className="font-bold text-gray-600 flex items-center">
                      Featured{" "}
                      <span className="cursor-pointer text-lg ml-4">
                        <RiQuestionLine />
                      </span>
                    </p>{" "}
                  </Tooltip>
                </p>
              </div>
            </div>
          }
          render={(item) => (
            <Link
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
              to={`/assistant/faq/articles/${item.id}/edit`}
            >
              <div className="grid grid-cols-12 items-center justify-center ">
                <div className="desktop:col-span-6 mobile:col-span-12 desktop:px-7 mobile:px-3">
                  <p className="font-bold text-primary-600 hover:text-primary-500">
                    {item.title}
                  </p>
                  <p className="text-gray-700 flex items-center">
                    {item.categoryid
                      ? getCategoryName(item.categoryid)
                      : "Not Categorised"}
                  </p>
                <div className="desktop:hidden mobile:flex items-center mt-3">
                  <small className="text-xs">
                  {item.is_published ? (
                    <span className="flex items-center rounded-sm ">
                      <RiCheckboxBlankCircleFill className="flex-none text-green-400 mr-1 text-xs" />{" "}
                      Published{" "}
                    </span>
                  ) : (
                    <span className="flex items-center  rounded-s">
                      <RiCheckboxBlankCircleFill className="flex-none text-yellow-400 mr-1 text-xs" />{" "}
                      Draft{" "}
                    </span>
                  )}
                  </small>
                  {item.is_featured && (
                  <small className="text-xs ml-2 px-3 border-l">
                    <span className="flex items-center rounded-sm ">
                      <RiStarFill className="text-yellow-500 text-xs mr-1" />
                      Featured{" "}
                    </span>
                  </small>
                  )}

                </div>
                </div>
                <div className="col-span-3 desktop:block mobile:hidden flex">
                  {item.is_published ? (
                    <span className="flex items-center rounded-sm py-5">
                      <RiCheckboxBlankCircleFill className="flex-none text-green-400 mr-2 text-lg" />{" "}
                      Published{" "}
                    </span>
                  ) : (
                    <span className="flex items-center  rounded-sm py-5">
                      <RiCheckboxBlankCircleFill className="flex-none text-yellow-400 mr-2 text-lg" />{" "}
                      Draft{" "}
                    </span>
                  )}
                </div>
                <div className="col-span-3 desktop:block mobile:hidden flex">
                  {item.is_featured && (
                    <RiStarFill className="text-yellow-500 text-xl" />
                  )}
                </div>
              </div>
            </Link>
          )}
        />
      </div>
    </>
  );
}
