import React from "react";
import Layout from "./components/structure/Layout";
import PricingModal from "./components/PricingModal";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import AssistantRouter from "./pages/assistant/AssistantRouter";
import WidgetsRouter from "./pages/widgets/WidgetsRouter";
import SettingsRouter from "./pages/settings/SettingsRouter";
import Onboard from "./pages/onboard/OnboardRouter";
import PaymentsLoader from "./pages/misc/PaymentsLoader";
import { useWorkspace } from "./WorkspaceContext";
import { useGraphQl } from "./GraphqlClient";
import { useQuery } from "react-query";
import { queryBillingInfo } from "./api/billing";
import toaster from "toasted-notes";

function AuthorisedApp() {
  const workspace = useWorkspace();
  const location = useLocation();
  const graphQL = useGraphQl();
  const { data } = useQuery("queryBillingInfo", () =>
    graphQL(queryBillingInfo, {
      appId: `${1}`, // hardcoded app id a temporary thing since we only have one app right now
      sourceId: `${workspace.data?.sourceid}`,
    })
  );

  if (workspace.data){
    (window ).pendo.initialize({
      visitor: {
        id: workspace.data.userid,
      },

      account: {
        id: workspace.data.accountid,
      },
    });
    if ((window).$crisp) {
      const $crisp=(window).$crisp;
     const {data:{storeUrl,user:{email}}} = workspace;
      $crisp.push(["set", "session:data", [[["store_url", storeUrl]]]]);
      $crisp.push(["set", "session:data", [[["app_name", "SupportCorner"]]]]);
      $crisp.push(["set", "user:email", [email]]);
      }
  }
  return (
    <>
    <PricingModal
        title="Select a plan to start"
        // This modal only opens in routes other than payment-loader plus if the plansID is null
        isOpen={(!data?.billingInfo.planId || data?.billingInfo.planId === 1) && location.pathname !== "/payment-loader"}
        onClose={() =>  toaster.notify("You have to select a plan to continue", {
          duration: 2000,
        })}
        nonClosable
      />
    <Switch>
    <Route exact path="/payment-loader" component={PaymentsLoader} />
      {workspace.data && workspace.data.onboarding_progress === 0 ? (
        <>
          <Route path="/" >
            <Redirect to="/get-started/"/>
          </Route>
          <Route path="/get-started" component={Onboard} />
        </>
      ) : (
        <>

          <Layout>
      <Switch>
        <Route path="/assistant" component={AssistantRouter} />
        <Route path="/settings" component={SettingsRouter} />
        <Route path="/widgets" component={WidgetsRouter} />
        <Route exact path="/" component={AssistantRouter} />
      </Switch>
    </Layout>
        </>
      )}
    </Switch>
    
    </>
  );
}

export default AuthorisedApp;
