import React, { useState } from "react";
import CustomiserUnit from "../../../components/CustomiserUnit";
import Button from "../../../components/Button";
import Textfield from "@atlaskit/textfield";
import {Checkbox} from "@atlaskit/checkbox";
import Popup from "@atlaskit/popup";
import Picker from "emoji-picker-react";
import { useWorkspace } from "../../../WorkspaceContext";
import { useHistory } from "react-router-dom";

const Assistant = (props) => {
  const [emojiPickerVisibility,setEmojiPickerVisibility] = useState(false);
  const workspace = useWorkspace();
  let history = useHistory();
  return (
    <>
      <CustomiserUnit
        title="Intro Emoji"
        description="Set an emoji to be featured on the assistant section"
      >
        <div className="flex">
          <Popup
            isOpen={emojiPickerVisibility}
            onClose={() => setEmojiPickerVisibility(false)}
            content={() => (
                <Picker
                  native
                  disableSearchBar
                  onEmojiClick={(event, emojiObject) => {
                    props.setFieldValue(
                      "contextConfigs.assistant.introEmoji",
                      emojiObject.emoji
                    );
                  }}
                />
            )}
            trigger={(triggerProps) => (
              <button
                {...triggerProps}
                onClick={() => setEmojiPickerVisibility(!emojiPickerVisibility)}
                type="outline"
                triggerText={props.values.contextConfigs.assistant.introEmoji}
                className="text-4xl px-4 py-3 border rounded-lg"
              >
                {props.values.contextConfigs.assistant.introEmoji}
              </button>
            )}
          />
        </div>
      </CustomiserUnit>
      <CustomiserUnit
        title="Welcome title"
        description="To edit the title displayed in the assistant section go to the language settings page"
      >
        <Button
          onClick={() => {
            history.push(`/settings/language/corner-widget/`);
          }}
          type="ghost"
          className="flex-shrink-0"
        >
          Go to language settings
        </Button>
      </CustomiserUnit>
      <CustomiserUnit
      paywall={
        !workspace.data?.feature_flag?.apps?.supportcorner?.articles_search_enabled
      }
      paywallPlan={5}
        title="Article search"
        description="Give your visitors option to search through your FAQ articles "
      >
        <Checkbox
          onChange={(e) => {
            props.setFieldValue(
              "contextConfigs.assistant.search",
              e.target.checked
            );
          }}
          label="Show search box"
          name="checkbox-basic"
          defaultChecked={props.values.contextConfigs.assistant.search}
        />
      </CustomiserUnit>
      <CustomiserUnit
        paywall={
          !workspace.data?.feature_flag?.apps?.supportcorner?.contact_options
            ?.whatsapp
        }
        paywallPlan={5}
        title="Whatsapp Integration"
        description="Give your phone number with your international code, dont include spaces or + (eg: 441134960999)"
      >
        <Textfield
          id="whatsapp"
          placeholder="Phone Number"
          autoFocus={true}
          name="basic"
          defaultValue={
            props.values.contextConfigs.assistant.contactOptions.whatsapp
          }
          onChange={(e) => {
            props.setFieldValue(
              "contextConfigs.assistant.contactOptions.whatsapp",
              e.target.value
            );
          }}
        />
      </CustomiserUnit>
      <CustomiserUnit
        paywall={
          !workspace.data?.feature_flag?.apps?.supportcorner?.contact_options
            ?.messenger
        }
        paywallPlan={5}
        title="Facebook Messenger Integration"
        description="Give your m.me URL (eg: https://m.me/cornercartio)"
      >
        <Textfield
          id="messenger"
          placeholder="m.me URL"
          autoFocus={true}
          name="basic"
          defaultValue={
            props.values.contextConfigs.assistant.contactOptions.messenger
          }
          onChange={(e) => {
            props.setFieldValue(
              "contextConfigs.assistant.contactOptions.messenger",
              e.target.value
            );
          }}
        />
      </CustomiserUnit>
      <CustomiserUnit
        paywall={
          !workspace.data?.feature_flag?.apps?.supportcorner?.contact_options
            ?.email
        }
        paywallPlan={5}
        title="Email Integration"
        description="Give your contact email id for your customers  (eg: abc@xyz.com)"
      >
        <Textfield
          id="email"
          placeholder="Email"
          autoFocus={true}
          name="basic"
          defaultValue={
            props.values.contextConfigs.assistant.contactOptions.email
          }
          onChange={(e) => {
            props.setFieldValue(
              "contextConfigs.assistant.contactOptions.email",
              e.target.value
            );
          }}
        />
      </CustomiserUnit>
      <CustomiserUnit
        paywall={
          !workspace.data?.feature_flag?.apps?.supportcorner?.contact_options
            ?.sms
        }
        paywallPlan={5}
        title="SMS Integration"
        description="Give your contact number so that visitors could redirect to their sms app you from the widget (eg: +441134960999)"
      >
        <Textfield
          id="sms"
          placeholder="+441134960999"
          autoFocus={true}
          name="basic"
          defaultValue={
            props.values.contextConfigs.assistant.contactOptions.sms
          }
          onChange={(e) => {
            props.setFieldValue(
              "contextConfigs.assistant.contactOptions.sms",
              e.target.value
            );
          }}
        />
      </CustomiserUnit>

    </>
  );
};

export default Assistant;
