import React, { useState, useEffect } from "react";
import { useWorkspace } from "../../WorkspaceContext";
import { RiStore2Fill } from "react-icons/ri";
import {
  RiArrowRightSLine,
  RiChatSmile3Fill,
  RiSettings4Fill,
  RiArrowDropRightFill,
  RiBookMarkFill,
} from "react-icons/ri";
import Tree, { RenderItemParams, TreeItem } from "@atlaskit/tree";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";

{/* type ItemId = number; */}
{/* interface TreeData { */}
{/*   rootId: ItemId; */}
{/*   items: Record<ItemId, TreeItem>; */}
{/* } */}

const widgetsSectionNavTree = {
  rootId: 1,
  items: {
    1: {
      id: 1,
      children: [2, 6],
      hasChildren: true,
    },
    2: {
      id: 2,
      children: [3, 4, 5],
      isExpanded: true,
      hasChildren: true,
      data: {
        name: "Corner Widget",
        type: "title",
        link: "/widgets/corner-widget/look-and-feel/",
      },
    },
    3: {
      id: 3,
      children: [],
      data: {
        name: "Look & Feel",
        type: "page",
        link: "/widgets/corner-widget/look-and-feel/",
      },
    },
    4: {
      id: 4,
      children: [],
      data: {
        name: "Assistant",
        type: "page",
        link: "/widgets/corner-widget/assistant/",
      },
    },
    5: {
      id: 5,
      children: [],
      data: {
        name: "Preferences",
        type: "page",
        link: "/widgets/corner-widget/preferences/",
      },
    },
    6: {
      id: 6,
      children: [7, 8],
      isExpanded: true,
      hasChildren: true,
      data: {
        name: "Launcher",
        type: "title",
        link: "/widgets/launchers/corner-button/",
      },
    },
    7: {
      id: 7,
      children: [],
      data: {
        name: "Corner Button",
        type: "page",
        link: "/widgets/launchers/corner-button/",
      },
    },
    8: {
      id: 8,
      children: [],
      data: {
        name: " Side Tab",
        type: "page",
        link: "/widgets/launchers/side-tab/",
      },
    },
  },
};

const faqSectionNavTree= {
  rootId: 1,
  items: {
    1: {
      id: 1,
      children: [2],
      hasChildren: true,
    },
    2: {
      id: 2,
      children: [3, 4, 5],
      isExpanded: true,
      hasChildren: true,
      data: {
        name: "FAQ",
        type: "title",
        link: "/assistant/faq/articles/",
      },
    },
    3: {
      id: 3,
      children: [],
      data: {
        name: "Articles",
        type: "page",
        link: "/assistant/faq/articles/",
      },
    },
    4: {
      id: 4,
      children: [],
      data: {
        name: "Categories",
        type: "page",
        link: "/assistant/faq/categories/",
      },
    },
    5: {
      id: 5,
      children: [],
      data: {
        name: "FAQ Page",
        type: "page",
        link: "/assistant/faq/page/",
      },
    },
  },
};
const settingsSectionNavTree = {
  rootId: 1,
  items: {
    1: {
      id: 1,
      children: [2,4],
      hasChildren: true,
    },
    2: {
      id: 2,
      children: [3],
      isExpanded: true,
      hasChildren: true,
      data: {
        name: "Account",
        type: "title",
        link: "/settings/account/billing/",
      },
    },
    3: {
      id: 3,
      children: [],
      data: {
        name: "Billing",
        type: "page",
        link: "/settings/account/billing/",
      },
    },
    4: {
      id: 4,
      children: [5,6,7],
      isExpanded: true,
      hasChildren: true,
      data: {
        name: "Language Settings",
        type: "title",
        link: "/settings/language/general/",
      },
    },
    5: {
      id: 5,
      children: [],
      data: {
        name: "General",
        type: "page",
        link: "/settings/language/general/",
      },
    },
    6: {
      id: 6,
      children: [],
      data: {
        name: "Corner Widget",
        type: "page",
        link: "/settings/language/corner-widget/",
      },
    },
    7: {
      id: 7,
      children: [],
      data: {
        name: "Faq Page",
        type: "page",
        link: "/settings/language/faq-page/",
      },
    }
  },
};

const SectionNav = () => {
  const workspace = useWorkspace();
  const [sectionNavTreeData, setSectionNavTreeData] = useState(
    widgetsSectionNavTree
  );
  const [headerIcon, setHeaderIcon] = useState(<RiArrowDropRightFill />);
  const [headerText, setHeaderText] = useState("Corner Cart");

  const SectionLink = ({ item } ) => {
    let match = useRouteMatch(item.data.link);
    const isSelected = match?.path === item.data.link;
    return (
      <NavLink to={item.data.link} strict>
        <div
          className={` py-2 px-4 mt-1 rounded-md  ${
            isSelected &&
            item.data.type === "page" &&
            "bg-primary-400 bg-opacity-10"
          } ${
            item.data.type === "page" &&
            " hover:bg-primary-400 hover:bg-opacity-10"
          }`}
        >
          {item.data.type === "title" ? (
            <p className="font-bold text-primary-600">{item.data.name}</p>
          ) : (
            <p
              className={`flex text-primary-500 items-center ${
                isSelected && "font-bold"
              }`}
            >
              {isSelected && (
                <span className="mr-1">
                  <RiArrowRightSLine />
                </span>
              )}
              <span>
              {item.data.name}
              </span>
            </p>
          )}
        </div>
      </NavLink>
    );
  };

  let location = useLocation();
  const pageRoute = location.pathname.split("/")[1];

  useEffect(() => {
    const initializeSidebar = () => {
      switch (pageRoute) {
        case "widgets": {
          setHeaderIcon(<RiChatSmile3Fill />);
          setHeaderText("Widgets");
          setSectionNavTreeData(widgetsSectionNavTree);
          break;
        }
        case "assistant": {
          setHeaderIcon(<RiBookMarkFill />);
          setHeaderText("Assistant");
          setSectionNavTreeData(faqSectionNavTree);
          break;
        }
        case "settings": {
          setHeaderIcon(<RiSettings4Fill />);
          setHeaderText("Settings");
          setSectionNavTreeData(settingsSectionNavTree);
          break;
        }
        default: {
          setHeaderIcon(<RiArrowDropRightFill />);
          setHeaderText("CornerCart");
          break;
        }
      }
    };
    initializeSidebar();
  }, [pageRoute]);

  return (
    <>
      <div className="flex items-center h-24 flex-none px-8 border-b ">
        <div className="text-3xl mr-2 text-primary-500">{headerIcon}</div>
        <h1 className="font-extrabold text-primary-500">{headerText}</h1>
      </div>
      <div className="px-4 mt-5 flex-grow">
        <Tree
          tree={sectionNavTreeData}
          renderItem={({ item, provided } ) => {
            return (
              <div
                className="cursor-pointer"
                ref={provided.innerRef}
                {...provided.draggableProps}
              >
                <SectionLink item={item} />
              </div>
            );
          }}
        />
      </div>
      <div>
        <div>
          <span className="text-sm justify-center flex items-center  text-black opacity-30 mb-3">
            <RiStore2Fill className="mr-2" /> {workspace.data.storeUrl}
          </span>
        </div>
      </div>
    </>
  );
};

export default SectionNav;
