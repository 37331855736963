import React from "react";
import CustomiserUnit from "../../../components/CustomiserUnit";
import Select from "@atlaskit/select";
import { Checkbox } from "@atlaskit/checkbox";
import Textfield from "@atlaskit/textfield";
import {
  RiCheckboxBlankCircleFill,
  RiCheckboxBlankCircleLine,
  RiAlarmWarningFill,
} from "react-icons/ri";

const SideTab = (props) => {
  const widgetStatus = [
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleFill className="flex-none text-green-400 mr-2 text-lg" />{" "}
          Active{" "}
        </div>
      ),
      value: true,
    },
    {
      label: (
        <div className="flex items-center">
          <RiCheckboxBlankCircleLine className="flex-none text-red-600 mr-2 text-lg" />{" "}
          Deactivated{" "}
        </div>
      ),
      value: false,
    },
  ];
  const OnClickOptions = [
    { label: "Open widget", value: "assistant" },
    { label: "Open FAQ page", value: "faq-page" },
  ];

  const visibilityOptions = [
    { label: "Show", value: true },
    { label: "Hide", value: false },
  ];

  return (
    <>
      <CustomiserUnit
        title="Enable Side Tab"
        description="A small tab that appears on the left or right side of your website "
      >
        <Select
          value={widgetStatus.find(
            (option) => option.value === props.values.sideTab.isActive
          )}
          onChange={(e) =>
            props.setFieldValue("sideTab.isActive", e.value)
          }
          className="w-48 mr-2"
          classNamePrefix="react-select"
          options={widgetStatus}
          placeholder="Select widget position"
        />
      </CustomiserUnit>

      <CustomiserUnit
        disabled={!props.values.sideTab.isActive}
        title="Visibility"
        description="Select whether the launcher should be shown in mobile/desktop "
      >
        <div className="flex items-center">
          <div>
            <label className="text-xs text-gray-700">Desktop</label>
            <Select
              value={visibilityOptions.find(
                (option) =>
                  option.value === props.values.sideTab.visibility.desktop
              )}
              onChange={(e) =>
                props.setFieldValue("sideTab.visibility.desktop", e.value)
              }
              className="w-32"
              classNamePrefix="react-select"
              options={visibilityOptions}
              placeholder="Size"
            />
          </div>
          <div className="ml-4">
            <label className="text-xs text-gray-700">Mobile</label>
            <Select
              value={visibilityOptions.find(
                (option) =>
                  option.value === props.values.sideTab.visibility.mobile
              )}
              onChange={(e) =>
                props.setFieldValue("sideTab.visibility.mobile", e.value)
              }
              className="w-32"
              classNamePrefix="react-select"
              options={visibilityOptions}
              placeholder="Size"
            />
          </div>
        </div>
        <div className="border rounded-lg mt-3 p-4 flex items-center justify-between">
            <Checkbox
              onChange={(e) => {
                props.setFieldValue(
                  "sideTab.visibility.showOnProductPage",
                  e.target.checked
                );
              }}
              label="Show on product pages"
              name="checkbox-basic"
              defaultChecked={
                props.values.sideTab.visibility.showOnProductPage
              }
            />
          <div className="border-r h-6 w-1" />
          <Checkbox
              onChange={(e) => {
                props.setFieldValue(
                  "sideTab.visibility.showOnCollectionPage",
                  e.target.checked
                );
              }}
              label="Show on collection pages"
              name="checkbox-basic"
              defaultChecked={
                props.values.sideTab.visibility.showOnCollectionPage
              }
            />
        </div>
      </CustomiserUnit>

      <CustomiserUnit
        disabled={!props.values.sideTab.isActive}
        title="Tab Text"
        description="What text  should we displayed on the tab?"
      >
        <Textfield
          defaultValue={props.values.sideTab.text}
          onChange={(e) => {
            props.setFieldValue("sideTab.text", e.target.value);
          }}
          name="basic"
        />
        {props.values.sideTab.text.length > 20 && (
          <small className="text-red-500 flex items-center">
            <RiAlarmWarningFill />
            <span className="ml-2">
              Exceeded the recommended charachter length
            </span>
          </small>
        )}
      </CustomiserUnit>

      <CustomiserUnit
        disabled={!props.values.sideTab.isActive}
        title="On Click Action"
        description="Select what should happen when the side-tab is clicked"
      >
        <Select
          value={OnClickOptions.find(
            (option) => option.value === props.values.sideTab.onClick
          )}
          onChange={(e) => props.setFieldValue("sideTab.onClick", e.value)}
          className="w-64"
          classNamePrefix="react-select"
          options={OnClickOptions}
          placeholder="Select widget position"
        />
      </CustomiserUnit>

      <CustomiserUnit
        disabled={!props.values.sideTab.isActive}
        title="Vertical Position"
        description="Define the vertical positioning of the launcher"
      >
        <Textfield
          defaultValue={props.values.sideTab.verticalPositioning}
          onChange={(e) => {
            props.setFieldValue("sideTab.verticalPositioning", e.target.value);
          }}
          name="basic"
          type="number"
        />
      </CustomiserUnit>
    </>
  );
};

export default SideTab;
