import React, { useState } from "react";
import Button from "../Button";
import Cookies from "js-cookie";

const ReviewAsker = () => {
  const [isReviewAskerOpen, setIsReviewAskerOpen] = useState(false);

  return (
    <>
      {!Cookies.get("hideReviewAsker") && !isReviewAskerOpen && (
        <a
          role="presentation"
          onClick={() => setIsReviewAskerOpen(true)}
          className="mobile:hidden desktop:block fixed bottom-0 left-0 z-30"
        >
            <div className="bg-white rounded-md p-1 text-xs m-2 shadow-lg bg-opacity-75">
              Hey there, <br /> do you have a minute?
            </div>
          <img
            className=""
            width="60px"
            src="https://assets.cornercart.io/coca-v2/images/experiments/head.png"
          />
        </a>
      )}
      {isReviewAskerOpen && (
        <div className="fixed bottom-0 left-0 w-screen h-screen z-20 ">
          <div className="absolute z-20 bottom-0 left-0">
            <div className="bg-white rounded-md shadow-xl px-6 py-4 max-w-md ml-7 -mb-16">
              <h2 className="text-bold">Hi there,</h2>
              <p>
                Would you mind writing a review on the Shopify app store please?
                <small>Your feedback really makes our day!</small>
              </p>
              <div className="flex justify-end mt-4">
                <Button
                  type="link"
                  onClick={() => {
                    Cookies.set("hideReviewAsker", "true", { expires: 1 / 28 });
                    setIsReviewAskerOpen(false);
                  }}
                  className="text-xs"
                >
                  Remind me later
                </Button>
                <Button
                  onClick={() => {
                    Cookies.set("hideReviewAsker", "true", { expires: 360 });
                  }}
                  type="primary"
                  className="text-xs"
                >
                  <a
                    href="https://apps.shopify.com/supportcorner#modal-show=ReviewListingModal&utm_campaign=in-app-review-ask&utm_medium=corner-guy&utm_source=dashboard"
                    target="_blank"
                  >
                    Write a review
                  </a>
                </Button>
              </div>
            </div>
            <img
              className=""
              width="160px"
              src="https://assets.cornercart.io/coca-v2/images/experiments/review-asker.png"
            />
          </div>
          <a
            className="fixed z-10 bottom-0 left-0 w-screen h-screen bg-primary-600 bg-opacity-50"
            role="presentation"
            onClick={() => {
              setIsReviewAskerOpen(false);
            }}
          ></a>
        </div>
      )}
    </>
  );
};

export default ReviewAsker;
