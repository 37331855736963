import React from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import PageViewContainer from "../../../components/structure/PageViewContainer";
import Billing from "./Billing";

export default function AccountSettings(props) {
  let { path } = useRouteMatch();
  const location = useLocation();
  const pageRoute = location.pathname.split("/")[3];

  const getTitle = () => {
    switch (pageRoute) {
      case "billing":
        return "Billing";
      default:
        return "Billing";
    }
  };

  return (
    <PageViewContainer title={getTitle()} subTitle="Account">
      <Switch>
        <Route
          path={`${path}/billing/`}
          render={() => <Billing {...props} />}
        />
        <Route path={`${path}`}>
          <Redirect to="/settings/account/billing/" />
        </Route>
      </Switch>
    </PageViewContainer>
  );
}
