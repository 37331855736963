import React from "react";
import { gql, GraphQLClient } from "graphql-request";
import {useAuth} from "./AuthenticationContext"
import firebase from "./firebaseIni";
import "firebase/auth";

/* Here we will export a graphql query fetch function
 * which will later can be used in subsequent componentes to
 * resolve queries and mutation without explicitly instantiating
 * graphql clients.
 */

const GraphqlClientContext = React.createContext({});


const getCurrentUserAuthorization = async () => {
  const currentUser = firebase.auth().currentUser
  if (currentUser) {
    const token = await currentUser.getIdToken()
    return `Bearer ${token}`
  } else {
    return ''
  }
}

const GraphqlClientProvider = (props) => {
  const authState= useAuth();
  const endpoint = process.env.REACT_APP_API_URL;

  const graphqlFetch = async (query, variables) => {
    try {
      if (authState.token)
        {const client = new GraphQLClient(endpoint, {
          headers: {
             authorization: await getCurrentUserAuthorization(),
          },
        });
      const data = await client.request(
        gql`
          ${query}
        `,
        variables
        );
        return data;
      }
    } catch (err) {
      //TODO add logging
      console.log(err);
      return null;
    }
  };
  return <GraphqlClientContext.Provider value={graphqlFetch} {...props} />;
};

const useGraphQl = () => React.useContext(GraphqlClientContext);

export { GraphqlClientProvider, useGraphQl,gql };
